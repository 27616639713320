/* eslint-disable max-lines */

import type { UpsertIdentifierPayload } from 'actions/identifier';
import {
  addNewLanguageMatcher,
  clearIdentifier,
  discardIdentifierChanges,
  fetchLanguageMatcherTypes,
  promoteIdentifier,
  setActiveLanguageMatcherId,
  setCompareModeIdentifier,
  setCustomerGroupIdentifier,
  setHasChanges,
  upsertIdentifier,
} from 'actions/identifier';
import CollapsableMainSection from 'components/CollapsableCard/CollapsableMainSection';
import EmptyLanguageMatcherState from 'components/EmptyLanguageMatcherState';
import LeavePageModal from 'components/LeavePageModal';
import Modal from 'components/Modal';
import { matcherTypeLabelMap } from 'constants/annotator';
import { MODAL_BACKGROUND } from 'constants/common';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  getActiveLanguageMatcher,
  getActiveLanguageMatcherId,
  getChangedLanguageMatchersNames,
  getCompareMode,
  getLanguageMatcherFromSelectedIdentifier,
  getLanguageMatcherTypes,
  getSelectedIdentifierRevision,
  getShouldDisableEdit,
  unsavedChanges,
} from 'selectors/identifier';
import { useSelector } from 'store';
import type { UUID } from 'types';
import { v4 as uuidv4 } from 'uuid';
import AdjacentTokensForm from './AdjacentTokensForm';
import DeleteMatcherButton from './DeleteMatcherButton';
import LanguageMatcherForm from './LanguageMatcherForm';
import TokenTypeForm from './TokenTypeForm';

type LanguageMatcherType = {
  identifierData: UpsertIdentifierPayload;
};

const LanguageMatcherRedesign: React.FC<LanguageMatcherType> = ({ identifierData }) => {
  const dispatch = useDispatch();

  const [isSaved, setIsSaved] = useState(false);
  const [isPromoteModalOpen, setIsPromoteModalOpen] = useState(false);

  const identifierRevision = useSelector(getSelectedIdentifierRevision);
  const unsaved = useSelector(unsavedChanges);
  const activeLanguageMatcher = useSelector(getActiveLanguageMatcher);
  const activeLanguageMatcherId = useSelector(getActiveLanguageMatcherId);
  const languageMatcherTypes = useSelector(getLanguageMatcherTypes);
  const languageMatchers = useSelector((state) =>
    getLanguageMatcherFromSelectedIdentifier(state, '')
  );
  const changedLanguageMatchers = useSelector(getChangedLanguageMatchersNames);
  const compareMode = useSelector(getCompareMode);
  const shouldDisable = useSelector(getShouldDisableEdit);

  useEffect(() => {
    if (activeLanguageMatcher && activeLanguageMatcher.isNew) {
      const input = document.getElementById('name');
      input?.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeLanguageMatcherId]);

  useEffect(() => {
    dispatch(fetchLanguageMatcherTypes());
  }, [dispatch]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const toggleModal = (): void => {
    setIsPromoteModalOpen((b) => !b);
  };

  const handleLanguageMatcherClick = (languageMatcherId: UUID): void => {
    dispatch(setActiveLanguageMatcherId(languageMatcherId));
  };

  const onSubmit = (): void => {
    if (!identifierRevision) return;

    dispatch(
      upsertIdentifier({
        ...identifierData,
        identifierId: identifierRevision.uuid,
        options_config: {},
      })
    );
    setIsSaved(true);
  };

  const addLocalLanguageMatcher = (): void => {
    if (!identifierRevision) return;
    const newMatcherId = uuidv4();

    const defaultValues: { [key: string]: string | number } = {};
    Object.entries(languageMatcherTypes.adjacent_tokens).forEach(([key, field]) => {
      if (field != null && field.default) {
        defaultValues[key] = field.default;
      }
    });
    const matcherBody = {
      uuid: newMatcherId,
      name: `New ${languageMatchers.length + 1}`,
      saved: false,
      type: 'token',
      isNew: true,
      ...defaultValues,
    };

    dispatch(
      addNewLanguageMatcher({
        identifierId: identifierRevision.uuid,
        languageMatcherId: newMatcherId,
        matcherBody,
      })
    );
    handleLanguageMatcherClick(newMatcherId);
  };

  const handleExit = (): void => {
    dispatch(setCompareModeIdentifier(false));
  };

  const handlePromote = (): void => {
    if (identifierRevision?.identifier_head_uuid && identifierRevision.identifier_uuid) {
      dispatch(
        promoteIdentifier({
          identifierHead: identifierRevision.identifier_head_uuid,
          identifierId: identifierRevision.identifier_uuid,
        })
      );
    }
    toggleModal();
    dispatch(setCustomerGroupIdentifier('staging'));
    dispatch(setCompareModeIdentifier(false));
  };

  const renderLanguageMatcherForm = (): JSX.Element => {
    if (activeLanguageMatcher.type === 'token') {
      return <TokenTypeForm isSaved={isSaved} />;
    }
    if (activeLanguageMatcher.type === 'adjacent_tokens') {
      return <AdjacentTokensForm isSaved={isSaved} />;
    }
    return <LanguageMatcherForm isSaved={isSaved} />;
  };

  const saveButton = (
    <div className="h-8 flex flex-row items-end gap-2">
      {activeLanguageMatcher &&
        activeLanguageMatcher.type !== 'token' &&
        activeLanguageMatcher.type !== 'adjacent_tokens' && (
          <DeleteMatcherButton
            setValue={(): void => {
              // moot
            }}
            setHighlightedLanguageMatcher={(): void => {
              // moot
            }}
            activeLanguageMatcher={activeLanguageMatcher}
          />
        )}
      {activeLanguageMatcher && unsaved && (
        <button
          data-testid="save-matchers-button"
          type="button"
          className="button button--secondary h-full w-22 justify-center"
          onClick={(e): void => {
            dispatch(discardIdentifierChanges());
            e.stopPropagation();
          }}
        >
          <span className="font-bold">Discard</span>
        </button>
      )}
      {compareMode ? (
        <>
          <button
            data-testid="save-matchers-button"
            type="button"
            className="button button--secondary h-full w-22 justify-center text-body"
            onClick={(e): void => {
              handleExit();
              e.stopPropagation();
            }}
          >
            <span className="font-bold">Exit</span>
          </button>
          <button
            data-testid="save-matchers-button"
            type="button"
            className="button button--primary h-full w-44 justify-center text-body"
            disabled={shouldDisable || unsaved}
            onClick={(e): void => {
              toggleModal();
              e.stopPropagation();
            }}
          >
            <span className="font-bold text-white">Push to Production</span>
          </button>
        </>
      ) : (
        <button
          data-testid="save-matchers-button"
          type="button"
          className="button button--primary h-full w-22 justify-center text-body"
          disabled={shouldDisable || !unsaved}
          onClick={(e): void => {
            onSubmit();
            e.stopPropagation();
          }}
        >
          <span className="font-bold text-white">Save</span>
        </button>
      )}
    </div>
  );

  const renderBody = (): JSX.Element => (
    <>
      <div className="absolute top-5 left-0 right-0 h-40 z-0">{MODAL_BACKGROUND}</div>

      <div className="flex flex-col mt-30 mb-8 gap-4">
        <h2 className="text-heading-1">Push Changes?</h2>
        <div className="flex flex-col gap-2">
          <p className="text-body">Changes have been accepted for these matchers:</p>
          <ul className="list-disc list-outside pl-8 flex flex-col gap-1 text-body">
            {changedLanguageMatchers.map((l) => (
              <li key={l}>{l}</li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );

  return (
    <>
      <CollapsableMainSection
        headerTitle={
          activeLanguageMatcher
            ? `${activeLanguageMatcher?.name} (${
                matcherTypeLabelMap[activeLanguageMatcher?.type as keyof typeof matcherTypeLabelMap]
              })`
            : 'Matcher'
        }
        customAction={saveButton}
        containerClasses="h-full"
      >
        <div className="w-full min-h-104 h-full flex flex-col justify-center overflow-visible">
          <div
            className={`flex flex-col flex-1 overflow-y-auto pb-3 ${
              !activeLanguageMatcher && 'justify-center'
            }`}
          >
            {!activeLanguageMatcher ? (
              <EmptyLanguageMatcherState addNewLanguageMatcher={addLocalLanguageMatcher} />
            ) : (
              renderLanguageMatcherForm()
            )}
          </div>

          <LeavePageModal
            shouldBlockNavigation={unsaved}
            dicardAction={(): void => {
              dispatch(setHasChanges(false));
              dispatch(clearIdentifier());
            }}
          />
        </div>
      </CollapsableMainSection>
      {isPromoteModalOpen && (
        <Modal
          title=" "
          body={renderBody()}
          okButton
          okButtonText="Push to Prod"
          okButtonOnClick={handlePromote}
          okButtonStyle="whitespace-no-wrap w-full px-4 mr-4"
          cancelButtonText="Go Back"
          cancelButtonOnclick={(): void => setIsPromoteModalOpen(false)}
          toggleShowModal={toggleModal}
        />
      )}
    </>
  );
};

export default LanguageMatcherRedesign;

/* eslint-disable camelcase */

import {
  addActionsBulkEnvelope,
  addActionsBulkEnvelopeFailure,
  addActionsBulkEnvelopeRequest,
  addActionsBulkEnvelopeSuccess,
  addAllValuesToField,
  addAndRemoveValuesToTree,
  addBulkAllEnvelopeTags,
  addBulkAllEnvelopeTagsFailure,
  addBulkAllEnvelopeTagsRequest,
  addBulkAllEnvelopeTagsSuccess,
  addBulkEnvelopeTags,
  addBulkEnvelopeTagsFailure,
  addBulkEnvelopeTagsRequest,
  addBulkEnvelopeTagsSuccess,
  addCommentEnvelope,
  addCommentEnvelopeFailure,
  addCommentEnvelopeRequest,
  addCommentEnvelopeSuccess,
  addEnvelopeTag,
  addEnvelopeTagFailure,
  addEnvelopeTagFulfill,
  addEnvelopeTagRequest,
  addEnvelopeTagSuccess,
  addEnvelopeToSavedSearch,
  addFieldsToGroup,
  addNotifyEnvelope,
  addNotifyEnvelopeFailure,
  addNotifyEnvelopeRequest,
  addNotifyEnvelopeSuccess,
  addValueToTree,
  addValuesToGroup,
  bulkReviewEnvelope,
  bulkReviewEnvelopes,
  bulkReviewEnvelopesFailure,
  bulkReviewEnvelopesRequest,
  bulkReviewEnvelopesSuccess,
  bulkReviewQueryEnvelope,
  bulkReviewQueryEnvelopeFailure,
  bulkReviewQueryEnvelopeRequest,
  bulkReviewQueryEnvelopeSuccess,
  changeConditionalByFieldValue,
  changeConditionalType,
  changeEnvelopeInReview,
  clearSelectedFields,
  clearSelectedFilters,
  createModelMetrics,
  createModelMetricsFailure,
  createModelMetricsRequest,
  createModelMetricsSuccess,
  createSample,
  createSampleFailure,
  createSampleRequest,
  createSampleSuccess,
  deleteURLParams,
  exportEnvelopesSearch,
  exportEnvelopesSearchFailure,
  exportEnvelopesSearchRequest,
  exportEnvelopesSearchSuccess,
  exportNgram,
  exportNgramFailure,
  exportNgramRequest,
  exportNgramSuccess,
  exportSingleEnvelope,
  extractBulk,
  extractBulkFailure,
  extractBulkRequest,
  extractBulkSuccess,
  extractRecipientDomains,
  extractRecipientDomainsFailure,
  extractRecipientDomainsRequest,
  extractRecipientDomainsSuccess,
  extractSenderDomains,
  extractSenderDomainsFailure,
  extractSenderDomainsRequest,
  extractSenderDomainsSuccess,
  fetchAggs,
  fetchAggsFailure,
  fetchAggsRequest,
  fetchAggsSuccess,
  fetchAllEnvelopes,
  fetchAllEnvelopesFailure,
  fetchAllEnvelopesForSample,
  fetchAllEnvelopesPrototypeSuccess,
  fetchAllEnvelopesRequest,
  fetchAllEnvelopesSuccess,
  fetchEnvelopeAttachments,
  fetchEnvelopeAttachmentsFailure,
  fetchEnvelopeAttachmentsFulfill,
  fetchEnvelopeAttachmentsRequest,
  fetchEnvelopeAttachmentsSuccess,
  fetchEnvelopeEsDocs,
  fetchEnvelopeEsDocsAsIndexed,
  fetchEnvelopeEsDocsAsIndexedFailure,
  fetchEnvelopeEsDocsAsIndexedRequest,
  fetchEnvelopeEsDocsAsIndexedSuccess,
  fetchEnvelopeEsDocsFailure,
  fetchEnvelopeEsDocsRequest,
  fetchEnvelopeEsDocsSuccess,
  fetchEnvelopeThread,
  fetchEnvelopeThreadFailure,
  fetchEnvelopeThreadRequest,
  fetchEnvelopeThreadSuccess,
  fetchEventSummary,
  fetchFlaggedText,
  fetchFlaggedTextFailure,
  fetchFlaggedTextFulfill,
  fetchFlaggedTextRequest,
  fetchFlaggedTextSuccess,
  fetchMoreLikeThis,
  fetchMoreLikeThisFulfill,
  fetchMoreLikeThisRequest,
  fetchSingleEnvelope,
  fetchSingleEnvelopeFailure,
  fetchSingleEnvelopeRequest,
  fetchSingleEnvelopeSuccess,
  getModelMetrics,
  getModelMetricsFailure,
  getModelMetricsRequest,
  getModelMetricsSuccess,
  makeSearchOnSandbox,
  markEnvelopeAsRead,
  markEnvelopeAsReadFailure,
  markEnvelopeAsReadRequest,
  markEnvelopeAsReadSuccess,
  removeBulkAllEnvelopeTags,
  removeBulkEnvelopeTags,
  removeBulkEnvelopeTagsFailure,
  removeBulkEnvelopeTagsRequest,
  removeBulkEnvelopeTagsSuccess,
  removeEnvelopeFromSavedSearch,
  removeEnvelopeTag,
  removeEnvelopeTagFailure,
  removeEnvelopeTagFulfill,
  removeEnvelopeTagRequest,
  removeEnvelopeTagSuccess,
  removeFieldFromTree,
  removeFieldsFromGroup,
  removeValueFromTree,
  removeValuesFromGroup,
  removeValuesFromTree,
  replaceURLParams,
  reprocessBulk,
  reprocessBulkFailure,
  reprocessBulkRequest,
  reprocessBulkSuccess,
  reprocessEnvelope,
  reprocessEnvelopeFailure,
  reprocessEnvelopeRequest,
  reprocessEnvelopeSuccess,
  reprocessModelMetrics,
  reprocessModelMetricsFailure,
  reprocessModelMetricsRequest,
  reprocessModelMetricsSuccess,
  requestTreeFiltersToogle,
  reviewAndContinueNextEnvelope,
  reviewAndContinueNextEnvelopeFulfill,
  reviewAndContinueNextEnvelopeRequest,
  reviewEnvelope,
  reviewEnvelopeFailure,
  reviewEnvelopeRequest,
  reviewEnvelopeSuccess,
  selectCommunication,
  setTree,
  setURLParams,
  showActionAlert,
  showErrorAlert,
  showQueryErrorAlert,
  showSuccessAlert,
  starEnvelope,
  starEnvelopeFailure,
  starEnvelopeRequest,
  starEnvelopeSuccess,
  updateAssignmentCounts,
  upsertSavedSearch,
  upsertSavedSearchSuccess,
} from 'actions';
import { setHasEventsFilter } from 'actions/envelopeListView';
import { apiClient as LitLingoClient, apiClientV2 as LitLingoClientV2 } from 'client';
import { getLocation, push } from 'connected-react-router';
import { andFilters } from 'constants/filtersMappingValues';
import { resourceQueryParamName } from 'constants/resourceQueryNames';
import { cloneDeep } from 'lodash';
import { matchPath } from 'react-router-dom';
import { GlobalState } from 'reducers';
import {
  all,
  call,
  debounce,
  delay,
  put,
  select,
  take,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import routes from 'routes';
import { getCustomerDomain, getUser } from 'selectors/auth';
import getSelectedEnvelope, {
  ATTACHMENT_TRANSLATED_TYPES,
  TRANSLATION_COMM_TYPES,
  getDeclaredStatus,
  getTags,
} from 'selectors/envelopeReview';
import {
  getEnvelope,
  getEnvelopesFlaggedTextListIds,
  getLastReview,
  getTree,
} from 'selectors/envelopes';
import { getNavParamsByResource } from 'selectors/nav';
import type { API, APIV2, Communication, RouteParams, SagaReturn } from 'types';
import sortCommunications from 'utils/communications';

import {
  Condition,
  DataNode,
  Operator,
  Tree,
  addAllValuesToField as addAllValuesToFieldAction,
  addDatesToFiltersSearch,
  addFieldToGroup,
  addToGroup,
  buildFSFromParams,
  changeConditional,
  changeConditionalByField,
  fillDefaultTree,
  findFieldFromValue,
  getAllFields,
  getNavParamsFromFilters,
  lookForField,
  removeField,
  removeFieldFromGroup,
  removeFields,
  removeFromGroup,
  removeValue,
  setNewValue,
  transformToString,
} from 'utils/parserTree';
import { getParamsFromUrl, getRoute, reverse } from 'utils/urls';
import { v4 } from 'uuid';
import { updateAssignmentCountsSaga } from './assignments';

interface ResponseSummary extends API.Envelopes.Summary {
  tree?: Tree;
  has_events?: string[];
}
function* fetchAllEnvelopesSaga({ payload }: ReturnType<typeof fetchAllEnvelopes>): SagaReturn {
  yield put(fetchAllEnvelopesRequest());
  const resourceParams = (yield select(
    getNavParamsByResource(resourceQueryParamName.envelopes)
  )) as ReturnType<ReturnType<typeof getNavParamsByResource>>;
  const strippedParams = { ...resourceParams };

  const actualTree = (yield select(getTree)) as ReturnType<typeof getTree>;
  if ('states' in strippedParams) {
    strippedParams.event_states = strippedParams.states;
    delete strippedParams.states;
  }
  let params = {
    include_count: true,
    ...strippedParams,
    ...(payload || {}),
  } as {
    include_count: boolean;
    should_return_tree?: boolean;
  } & Record<Exclude<string, 'include_count' | 'should_return_tree'>, string | string[]>;

  if ('filters_search' in strippedParams) {
    let _offset: string = strippedParams.offset as string;

    // @ts-ignore
    if (payload?.offset) {
      // @ts-ignore
      _offset = payload.offset;
    }
    // @ts-ignore
    params = {
      include_count: true,
      offset: _offset,
      filters_search: strippedParams.filters_search,
      order_by: strippedParams.order_by,
      limit: strippedParams.limit,
      order_desc: strippedParams.order_desc,
    };

    if (
      (strippedParams.created_after || strippedParams.created_before) &&
      !strippedParams.days_from_oor
    ) {
      const fs = addDatesToFiltersSearch(strippedParams, actualTree);
      if (fs) {
        params.filters_search = fs;
      }
    }

    if (strippedParams.sample_uuid) params.sample_uuid = strippedParams.sample_uuid;
  }

  if (strippedParams.has_events && strippedParams.has_events.length > 0)
    params.has_events = strippedParams.has_events;

  params.should_return_tree = false;

  const response = (yield call([LitLingoClient.resources.envelopes.extras, 'summary'], {
    params,
  })) as API.Response<ResponseSummary>;

  const { router } = (yield select()) as GlobalState;
  const { pathname } = router.location;
  const { auth } = (yield select()) as GlobalState;
  const spec = routes.find((route) => matchPath(pathname, route));
  const routeSpec = getRoute(spec, auth.user.customer?.config);

  if (response.error != null) {
    yield put(showQueryErrorAlert('summary'));
    yield put(fetchAllEnvelopesFailure(response.error));
    // @ts-ignore
  } else if ((strippedParams.offset === '0' || !strippedParams.offset) && !payload?.offset) {
    const { tree, has_events, ...data } = response.data;
    data.records = data.records.map((record) => {
      record.page_id = params.offset as string;
      return record;
    });
    yield put(fetchAllEnvelopesSuccess(data));
    yield put(requestTreeFiltersToogle({ value: false }));
    // const envelopeIds = response.data.records.map((record) => record.envelope.uuid);
    // yield put(fetchFlaggedText({ envelopeIds }));
    if (tree) {
      const fullTree = fillDefaultTree(tree, strippedParams);
      yield put(setTree({ tree: fullTree }));
    }

    if (
      has_events &&
      routeSpec?.name === 'envelope-list' &&
      (!params.has_events || params.has_events.length === 0)
    ) {
      yield put(
        setHasEventsFilter({ value: has_events.map((event) => event.toString()), requery: false })
      );
    }
  } else {
    const { tree, has_events, ...data } = response.data;
    data.records = data.records.map((record) => {
      record.page_id = params.offset as string;
      return record;
    });

    yield put(
      fetchAllEnvelopesPrototypeSuccess({
        ...data,
        // @ts-ignore
        prepend: parseInt(strippedParams.offset as string, 10) > payload?.offset,
      })
    );

    yield put(requestTreeFiltersToogle({ value: false }));
    // const envelopeIds = response.data.records.map((record) => record.envelope.uuid);
    // yield put(fetchFlaggedText({ envelopeIds }));
    if (tree) {
      const fullTree = fillDefaultTree(tree, strippedParams);
      yield put(setTree({ tree: fullTree }));
    }
    if (
      has_events &&
      routeSpec?.name === 'envelope-list' &&
      (!params.has_events || params.has_events.length === 0)
    ) {
      yield put(
        setHasEventsFilter({ value: has_events.map((event) => event.toString()), requery: false })
      );
    }
  }
}

function* fetchAggsSaga({ payload }: ReturnType<typeof fetchAggs>): SagaReturn {
  const { aggQuery } = payload;
  const aggString = JSON.stringify(aggQuery);

  const resourceParams = (yield select(
    getNavParamsByResource(resourceQueryParamName.envelopes)
  )) as ReturnType<ReturnType<typeof getNavParamsByResource>>;

  yield put(fetchAggsRequest());

  const params = {
    agg_query: aggString,
    ...resourceParams,
  };

  const response = (yield call([LitLingoClient.resources.envelopes.extras, 'aggs'], {
    params,
  })) as API.Response<ResponseSummary>;

  if (response.error != null) {
    yield put(fetchAggsFailure(response.error));
  } else {
    const { data } = response;
    const sucessObject = { expId: payload.expId, ...data };
    yield put(fetchAggsSuccess(sucessObject));
  }
}

function* fetchAllEnvelopesForSampleSaga({
  payload,
}: ReturnType<typeof fetchAllEnvelopesForSample>): SagaReturn {
  yield put(fetchAllEnvelopesRequest());

  const { tree: originalTree, smartSample, ...strippedParams } = { ...payload };

  let nTree = { ...originalTree };
  nTree = removeField(nTree, 'sample_uuids');
  if (!smartSample || (smartSample && payload.created_after && payload.created_before)) {
    nTree = removeField(nTree, 'date_range');
    nTree = setNewValue(
      nTree,
      'date_range',
      `${payload.created_after}<>${payload.created_before}`,
      ''
    );
  }

  const treeFs = transformToString(nTree);

  if ('states' in strippedParams) {
    strippedParams.event_states = strippedParams.states;
    delete strippedParams.states;
  }
  const params = {
    include_count: 'true',
    ...strippedParams,
    filters_search: treeFs,
    limit: '1',
  } as RouteParams;

  const response = (yield call([LitLingoClient.resources.envelopes.extras, 'summary'], {
    params,
  })) as API.Response<ResponseSummary>;

  const { router } = (yield select()) as GlobalState;
  const { pathname } = router.location;
  const { auth } = (yield select()) as GlobalState;
  const spec = routes.find((route) => matchPath(pathname, route));
  const routeSpec = getRoute(spec, auth.user.customer?.config);

  if (response.error != null) {
    yield put(fetchAllEnvelopesFailure(response.error));
    // @ts-ignore
  } else if ((strippedParams.offset === '0' || !strippedParams.offset) && !payload?.offset) {
    const { tree, has_events, ...data } = response.data;
    data.records = data.records.map((record) => {
      record.page_id = params.offset as string;
      return record;
    });
    yield put(fetchAllEnvelopesSuccess(data));
    yield put(requestTreeFiltersToogle({ value: false }));
    // const envelopeIds = response.data.records.map((record) => record.envelope.uuid);
    // yield put(fetchFlaggedText({ envelopeIds }));
    if (tree) {
      const fullTree = fillDefaultTree(tree, strippedParams);
      yield put(setTree({ tree: fullTree }));
    }

    if (
      has_events &&
      routeSpec?.name === 'envelope-list' &&
      (!params.has_events || params.has_events.length === 0)
    ) {
      yield put(
        setHasEventsFilter({ value: has_events.map((event) => event.toString()), requery: false })
      );
    }
  } else {
    const { tree, has_events, ...data } = response.data;
    data.records = data.records.map((record) => {
      record.page_id = params.offset as string;
      return record;
    });

    yield put(
      fetchAllEnvelopesPrototypeSuccess({
        ...data,
        // @ts-ignore
        prepend: parseInt(strippedParams.offset as string, 10) > payload?.offset,
      })
    );

    yield put(requestTreeFiltersToogle({ value: false }));
    // const envelopeIds = response.data.records.map((record) => record.envelope.uuid);
    // yield put(fetchFlaggedText({ envelopeIds }));
    if (tree) {
      const fullTree = fillDefaultTree(tree, strippedParams);
      yield put(setTree({ tree: fullTree }));
    }
    if (
      has_events &&
      routeSpec?.name === 'envelope-list' &&
      (!params.has_events || params.has_events.length === 0)
    ) {
      yield put(
        setHasEventsFilter({ value: has_events.map((event) => event.toString()), requery: false })
      );
    }
  }
}

export function* setHasEventsFilterSaga({
  payload,
}: ReturnType<typeof setHasEventsFilter>): SagaReturn {
  const { value: matchFilters, requery = true } = payload;
  const hasEvents = [...new Set(matchFilters)];
  const params = {
    envelopes__has_events: hasEvents,
    envelopes__offset: '0',
  };

  if (!requery) {
    yield put(replaceURLParams({ ...params, requery: 'false' }));
  } else {
    yield put(setURLParams({ ...params }));
  }
}

export function* fetchSingleEnvelopeSaga({
  payload,
}: ReturnType<typeof fetchSingleEnvelope>): SagaReturn {
  yield put(fetchSingleEnvelopeRequest());

  const response = (yield call(
    [LitLingoClient.resources.envelopes, 'retrieve'],
    payload.envelopeId,
    {
      params: {
        relationships: [
          'actions',
          'actions.created_by',
          'events',
          'events.actions',
          'events.campaign',
          'events.rule',
          'events.rule.identifiers',
          'events.rule.identifiers.identifier',
          'communications',
          'created_by.teams.name',
          'human_tags.tag_value',
          'locked_by',
          'action_summary',
          'reviews',
        ],
        include_pii: true,
      },
    }
  )) as API.Response<API.Envelopes.SingleEnvelope>;
  if (response.error != null) {
    yield put(fetchSingleEnvelopeFailure(response.error));
  } else {
    yield put(fetchSingleEnvelopeSuccess(response.data));
    if (payload.preview) {
      yield put(changeEnvelopeInReview({ envelope: response.data }));
      let version: Communication;

      const envelopeVersions = response.data?.communications
        ? response.data?.communications
            ?.filter(
              (c) =>
                c.communication_type !== 'attachment' &&
                !TRANSLATION_COMM_TYPES.includes(c.communication_type) &&
                !ATTACHMENT_TRANSLATED_TYPES.includes(c.communication_type)
            )
            .sort((a, b) => sortCommunications(a, b, true))
        : [];

      const versionBase = envelopeVersions[0];

      const envelopeTranslations = response.data.communications
        ? response.data.communications
            .filter((c) => TRANSLATION_COMM_TYPES.includes(c.communication_type))
            .sort(sortCommunications)
            .slice(0, 1)
        : [];
      const envTransalationBase = envelopeTranslations[0];

      if (envelopeTranslations.length) {
        version = envTransalationBase;
      } else {
        version = versionBase;
      }
      yield put(selectCommunication({ communication: version }));
    }
  }
}

function* fetchEnvelopeThreadSaga({ payload }: ReturnType<typeof fetchEnvelopeThread>): SagaReturn {
  yield put(fetchEnvelopeThreadRequest());

  const resourceParams = (yield select(
    getNavParamsByResource(resourceQueryParamName.envelopeThread)
  )) as ReturnType<ReturnType<typeof getNavParamsByResource>>;

  const params = {
    include_count: true,
    platform_thread_guid: payload.platformGuid,
    ...resourceParams,
    ...(payload.offset ? { offset: payload.offset } : {}),
    ...(payload.limit ? { limit: payload.limit } : {}),
  };

  const response = (yield call([LitLingoClient.resources.envelopes.extras, 'summary'], {
    params,
  })) as API.Response<API.Envelopes.Summary>;
  if (response.error != null) {
    yield put(fetchEnvelopeThreadFailure(response.error));
  } else {
    yield put(fetchEnvelopeThreadSuccess({ data: response.data, offset: payload.offset || 0 }));
  }
}

function* starEnvelopeSaga({ payload }: ReturnType<typeof starEnvelope>): SagaReturn {
  const { envelopeId } = payload;
  const body = {
    starred: payload.starred,
  };

  yield put(starEnvelopeRequest());

  const response = (yield call([LitLingoClient.resources.envelopes.extras, 'star'], {
    urlParams: { envelopeId },
    data: body,
  })) as API.Response<API.Envelopes.Star>;
  if (response.error != null) {
    yield put(starEnvelopeFailure(response.error));
  } else {
    yield put(starEnvelopeSuccess(response.data));
  }
}

function* markEnvelopeAsReadSaga({ payload }: ReturnType<typeof markEnvelopeAsRead>): SagaReturn {
  const { envelopeId } = payload;

  yield put(markEnvelopeAsReadRequest());

  const response = (yield call([LitLingoClient.resources.envelopes.extras, 'read'], {
    urlParams: { envelopeId },
    data: { is_read: true },
  })) as API.Response<API.Envelopes.Read>;
  if (response.error != null) {
    yield put(markEnvelopeAsReadFailure(response.error));
  } else {
    yield put(markEnvelopeAsReadSuccess(response.data));
  }
}

export function* addEnvelopeTagSaga(action: ReturnType<typeof addEnvelopeTag>): SagaReturn {
  const { envelopeId, tag, skipSaveEnvelope = false } = action.payload;

  yield put(addEnvelopeTagRequest());

  const response = (yield call([LitLingoClient.resources.envelopes.extras, 'addTag'], {
    urlParams: { envelopeId },
    data: { value: tag },
    params: {
      relationships: ['tags.tag_value', 'action_summary'],
    },
  })) as API.Response<API.Envelopes.AddTag>;
  if (response.error != null) {
    yield put(addEnvelopeTagFailure(response.error));
  } else if (!skipSaveEnvelope) {
    yield put(addEnvelopeTagSuccess(response.data));
  }
  yield put(addEnvelopeTagFulfill());
}

export function* removeEnvelopeTagSaga(action: ReturnType<typeof removeEnvelopeTag>): SagaReturn {
  const { envelopeId, tag, skipSaveEnvelope = false } = action.payload;

  yield put(removeEnvelopeTagRequest());

  const response = (yield call([LitLingoClient.resources.envelopes.extras, 'removeTag'], {
    urlParams: { envelopeId },
    data: { value: tag },
    params: {
      relationships: ['tags.tag_value', 'action_summary'],
    },
  })) as API.Response<API.Envelopes.RemoveTag>;
  if (response.error != null) {
    yield put(removeEnvelopeTagFailure(response.error));
  } else if (!skipSaveEnvelope) {
    yield put(removeEnvelopeTagSuccess(response.data));
  }
  yield put(removeEnvelopeTagFulfill());
}

function* addBulkEnvelopeTagsSaga(action: ReturnType<typeof addBulkEnvelopeTags>): SagaReturn {
  const { payload } = action;
  yield put(addBulkEnvelopeTagsRequest());

  const response = (yield call([LitLingoClient.resources.envelopes.extras, 'bulkAddTags'], {
    params: { relationships: ['tags.tag_value', 'action_summary'], include_pii: 'true' },
    data: { uuids: payload.uuids, value: payload.value },
  })) as API.Response<API.Envelopes.BulkAddTags>;
  if (response.error != null) {
    yield put(addBulkEnvelopeTagsFailure(response.error));
    yield put(showErrorAlert('Error while adding tags'));
  } else {
    yield put(addBulkEnvelopeTagsSuccess(response.data));
    yield put(showSuccessAlert('Tags added successfully'));
  }
}

function* removeBulkEnvelopeTagsSaga(
  action: ReturnType<typeof removeBulkEnvelopeTags>
): SagaReturn {
  const { payload } = action;
  yield put(removeBulkEnvelopeTagsRequest());

  const response = (yield call([LitLingoClient.resources.envelopes.extras, 'bulkRemoveTags'], {
    params: { relationships: ['tags.tag_value', 'action_summary'], include_pii: 'true' },
    data: { uuids: payload.uuids, value: payload.value },
  })) as API.Response<API.Envelopes.BulkAddTags>;
  if (response.error != null) {
    yield put(removeBulkEnvelopeTagsFailure(response.error));
    yield put(showErrorAlert('Error while removing tags'));
  } else {
    yield put(removeBulkEnvelopeTagsSuccess(response.data));
    yield put(showSuccessAlert('Tags removed successfully'));
  }
}

function* addBulkAllEnvelopeTagsSaga(
  action: ReturnType<typeof addBulkAllEnvelopeTags>
): SagaReturn {
  const { payload } = action;

  yield put(addBulkAllEnvelopeTagsRequest());

  const resourceParams = (yield select(
    getNavParamsByResource(resourceQueryParamName.envelopes)
  )) as ReturnType<ReturnType<typeof getNavParamsByResource>>;

  const params = {
    ...resourceParams,
    offset: 0,
    limit: -1,
  };

  const response = (yield call([LitLingoClient.resources.envelopes.extras, 'bulkAddAllTags'], {
    params,
    data: { tags: payload.tags },
  })) as API.Response<API.Envelopes.BulkAllAddTags>;

  if (response.error != null) {
    yield put(addBulkAllEnvelopeTagsFailure(response.error));
  } else if (!response.data.ok) {
    const error = new Error('Query params are required');
    yield put(addBulkAllEnvelopeTagsFailure(error));
  } else {
    yield put(addBulkAllEnvelopeTagsSuccess());
    yield put(showSuccessAlert('Tags job started, you will receive an email shortly'));
  }
}

function* removeBulkAllEnvelopeTagsSaga(
  action: ReturnType<typeof addBulkAllEnvelopeTags>
): SagaReturn {
  const { payload } = action;

  yield put(addBulkAllEnvelopeTagsRequest());

  const resourceParams = (yield select(
    getNavParamsByResource(resourceQueryParamName.envelopes)
  )) as ReturnType<ReturnType<typeof getNavParamsByResource>>;

  const strippedParams = { ...resourceParams };

  const params: Record<string, unknown> = {
    ...strippedParams,
    offset: 0,
    limit: -1,
  };

  const response = (yield call([LitLingoClient.resources.envelopes.extras, 'bulkRemoveAllTags'], {
    params,
    data: { tags: payload.tags },
  })) as API.Response<API.Envelopes.BulkAllRemoveTags>;

  if (response.error != null) {
    yield put(addBulkAllEnvelopeTagsFailure(response.error));
  } else if (!response.data.ok) {
    const error = new Error('Query params are required');
    yield put(addBulkAllEnvelopeTagsFailure(error));
  } else {
    yield put(addBulkAllEnvelopeTagsSuccess());
    yield put(showSuccessAlert('Tags job started, you will receive an email shortly'));
  }
}

function* fetchEnvelopeEsDocsSaga({ payload }: ReturnType<typeof fetchEnvelopeEsDocs>): SagaReturn {
  const { envelopeId } = payload;

  yield put(fetchEnvelopeEsDocsRequest());

  const response = (yield call([LitLingoClient.resources.envelopes.extras, 'esDocs'], {
    urlParams: { envelopeId },
  })) as API.Response<API.Envelopes.EsDocs>;
  if (response.error != null) {
    yield put(fetchEnvelopeEsDocsFailure(response.error));
  } else {
    yield put(fetchEnvelopeEsDocsSuccess(response.data));
  }
}

function* fetchEnvelopeEsDocsAsIndexedSaga({
  payload,
}: ReturnType<typeof fetchEnvelopeEsDocsAsIndexed>): SagaReturn {
  const { envelopeId } = payload;

  yield put(fetchEnvelopeEsDocsAsIndexedRequest());

  const response = (yield call([LitLingoClient.resources.envelopes.extras, 'esDocsAsIndexed'], {
    urlParams: { envelopeId },
  })) as API.Response<API.Envelopes.EsDocsAsIndexed>;
  if (response.error != null) {
    yield put(fetchEnvelopeEsDocsAsIndexedFailure(response.error));
  } else {
    yield put(fetchEnvelopeEsDocsAsIndexedSuccess(response.data));
  }
}

function* exportNgramSaga(): SagaReturn {
  yield put(exportNgramRequest());
  const resourceParams = (yield select(
    getNavParamsByResource(resourceQueryParamName.envelopes)
  )) as ReturnType<ReturnType<typeof getNavParamsByResource>>;
  const strippedParams = { ...resourceParams };

  if ('states' in strippedParams) {
    strippedParams.event_states = strippedParams.states;
    delete strippedParams.states;
  }
  const params: Record<string, unknown> = {
    include_count: true,
    ...strippedParams,
    limit: 10000,
  };

  const response = (yield call([LitLingoClient.resources.envelopes.extras, 'exportNgrams'], {
    params,
  })) as API.Response<API.Envelopes.Summary>;
  if (response.error != null) {
    yield put(exportNgramFailure(response.error));
  } else {
    yield put(exportNgramSuccess());
    yield put(showSuccessAlert('Exported successfully'));
  }
}

function* extractSenderDomainsSaga(): SagaReturn {
  yield put(extractSenderDomainsRequest());

  const resourceParams = (yield select(
    getNavParamsByResource(resourceQueryParamName.envelopes)
  )) as ReturnType<ReturnType<typeof getNavParamsByResource>>;

  const params = { include_count: true, ...resourceParams, limit: 10000 };

  const response = (yield call(
    [LitLingoClient.resources.customers.extras, 'extractSenderDomains'],
    { params }
  )) as API.Response<API.Customers.ReprocessWithFilter>;

  if (response.error != null || response.data == null) {
    yield put(extractSenderDomainsFailure(response.error));
  } else {
    yield put(extractSenderDomainsSuccess());
    yield put(showSuccessAlert('Sender domains extracted successfully'));
  }
}

function* extractRecipientDomainsSaga(): SagaReturn {
  yield put(extractRecipientDomainsRequest());

  const resourceParams = (yield select(
    getNavParamsByResource(resourceQueryParamName.envelopes)
  )) as ReturnType<ReturnType<typeof getNavParamsByResource>>;

  const params = { include_count: true, ...resourceParams, limit: 10000 };

  const response = (yield call(
    [LitLingoClient.resources.customers.extras, 'extractRecipientDomains'],
    { params }
  )) as API.Response<API.Customers.ReprocessWithFilter>;

  if (response.error != null || response.data == null) {
    yield put(extractRecipientDomainsFailure(response.error));
  } else {
    yield put(extractRecipientDomainsSuccess());
    yield put(showSuccessAlert('Recipient domains extracted successfully'));
  }
}

function* reprocessBulkSaga({ payload }: ReturnType<typeof reprocessBulk>): SagaReturn {
  const { starship } = payload;
  yield put(reprocessBulkRequest());
  const resourceParams = (yield select(
    getNavParamsByResource(resourceQueryParamName.envelopes)
  )) as ReturnType<ReturnType<typeof getNavParamsByResource>>;

  const params: Record<string, unknown> = {
    include_count: true,
    ...resourceParams,
    limit: 10000,
  };

  const response = (yield call([LitLingoClient.resources.customers.extras, 'reprocessWithFilter'], {
    params,
    data: { starship },
  })) as API.Response<API.Customers.ReprocessWithFilter>;
  if (response.error != null) {
    yield put(reprocessBulkFailure(response.error));
  } else {
    yield put(reprocessBulkSuccess());
    yield put(showSuccessAlert('Reprocess started successfully'));
  }
}

function* extractBulkSaga({ payload }: ReturnType<typeof reprocessBulk>): SagaReturn {
  const { starship } = payload;
  yield put(extractBulkRequest());
  const resourceParams = (yield select(
    getNavParamsByResource(resourceQueryParamName.envelopes)
  )) as ReturnType<ReturnType<typeof getNavParamsByResource>>;

  const params: Record<string, unknown> = {
    include_count: true,
    ...resourceParams,
    limit: 500,
  };

  const response = (yield call([LitLingoClient.resources.customers.extras, 'extractWithFilter'], {
    params,
    data: { starship },
  })) as API.Response<API.Customers.ReprocessWithFilter>;
  if (response.error != null) {
    yield put(extractBulkFailure(response.error));
  } else {
    yield put(extractBulkSuccess());
    yield put(showSuccessAlert('Extract started successfully'));
  }
}

function* exportEnvelopesSearchSaga(): SagaReturn {
  yield put(exportEnvelopesSearchRequest());
  const resourceParams = (yield select(
    getNavParamsByResource(resourceQueryParamName.envelopes)
  )) as ReturnType<ReturnType<typeof getNavParamsByResource>>;
  const strippedParams = { ...resourceParams };
  if ('states' in strippedParams) {
    strippedParams.event_states = strippedParams.states;
    delete strippedParams.states;
  }
  const params: Record<string, unknown> = {
    include_count: true,
    ...strippedParams,
    limit: 10000,
  };

  const response = (yield call([LitLingoClient.resources.envelopes.extras, 'exportSummaries'], {
    params,
  })) as API.Response<API.Envelopes.Summary>;
  if (response.error != null) {
    yield put(exportEnvelopesSearchFailure(response.error));
  } else {
    yield put(exportEnvelopesSearchSuccess());
    yield put(showSuccessAlert('Export initiated. You will receive an email shortly'));
  }
}

function* exportSingleEnvelopesSaga({
  payload,
}: ReturnType<typeof exportSingleEnvelope>): SagaReturn {
  const { envelopeId, communicationId } = payload;

  yield put(exportEnvelopesSearchRequest());

  const data: Record<string, unknown> = {
    communication_uuid: communicationId,
    envelope_uuid: envelopeId,
  };

  const response = (yield call([LitLingoClient.resources.envelopes.extras, 'exportSummary'], {
    data,
  })) as API.Response<API.Envelopes.Summary>;

  if (response.error != null) {
    yield put(exportEnvelopesSearchFailure(response.error));
  } else {
    yield put(exportEnvelopesSearchSuccess());
    yield put(showSuccessAlert('Export initiated. You will receive an email shortly'));
  }
}

function* addActionsBulkEnvelopeSaga({
  payload,
}: ReturnType<typeof addActionsBulkEnvelope>): SagaReturn {
  const { envelopeId, type, value, events } = payload;

  yield put(addActionsBulkEnvelopeRequest());

  const response = (yield call([LitLingoClient.resources.envelopes.extras, 'bulkAddAction'], {
    urlParams: { envelopeId },
    data: { type, value },
  })) as API.Response<API.Envelopes.BulkAddAction>;
  if (response.error != null) {
    yield put(addActionsBulkEnvelopeFailure(response.error));
  } else {
    yield put(addActionsBulkEnvelopeSuccess(response.data));
    yield all(events.map((event) => put(fetchEventSummary({ eventId: event.uuid }))));
  }
}

export function* reviewEnvelopeSaga({ payload }: ReturnType<typeof reviewEnvelope>): SagaReturn {
  const {
    envelopeId,
    value,
    secondsSpent,
    assignmentId,
    meta_data,
    isMultiple,
    showSkips = false,
  } = payload;

  yield put(reviewEnvelopeRequest());

  const response = (yield call([LitLingoClient.resources.envelopes.extras, 'review'], {
    urlParams: { envelopeId },
    data: { value, seconds_spent: secondsSpent, assignment_uuid: assignmentId, meta_data },
    params: {
      include_pii: 'true',
      relationships: [
        'actions',
        'events',
        'events.actions',
        'events.campaign',
        'events.rule',
        'communications',
        'created_by.teams.name',
        'created_by.name',
        'locked_by',
        'action_summary',
        'tags.tag_value',
        'rule',
        'campaign',
        'reviews',
      ],
    },
  })) as API.Response<API.Envelopes.AddAction>;
  if (response.error != null) {
    yield put(reviewEnvelopeFailure(response.error));
  } else {
    yield put(reviewEnvelopeSuccess(response.data));
    if (isMultiple) {
      yield put(fetchAllEnvelopes());
    }
    if (showSkips) {
      yield call(updateAssignmentCountsSaga, {
        payload: {
          assignmentId: assignmentId ?? '',
          envelopeIds: [envelopeId],
        },
        type: updateAssignmentCounts.toString(),
      });
    }
  }
}

export function* bulkReviewEnvelopesSaga({
  payload,
}: ReturnType<typeof bulkReviewEnvelopes>): SagaReturn {
  const { value, envelopeIds } = payload;

  yield put(bulkReviewEnvelopesRequest());

  const response = (yield call([LitLingoClient.resources.envelopes.extras, 'bulkReview'], {
    data: { value, uuids: envelopeIds },
  })) as API.Response<API.Envelopes.BulkAddTags>;

  if (response.error != null) {
    yield put(bulkReviewEnvelopesFailure(response.error));
  } else {
    yield put(bulkReviewEnvelopesSuccess(response.data));
    yield put(showSuccessAlert('Review value changed successfully'));
  }
}

export function* bulkReviewQueryEnvelopeSaga({
  payload,
}: ReturnType<typeof bulkReviewQueryEnvelope>): SagaReturn {
  const { value } = payload;

  const resourceParams = (yield select(
    getNavParamsByResource(resourceQueryParamName.envelopes)
  )) as ReturnType<ReturnType<typeof getNavParamsByResource>>;

  const params = {
    ...resourceParams,
    offset: 0,
    limit: -1,
  };

  yield put(bulkReviewQueryEnvelopeRequest());

  const response = (yield call([LitLingoClient.resources.envelopes.extras, 'bulkReviewQuery'], {
    data: { value },
    params,
  })) as API.Response<API.Envelopes.BulkReview>;

  if (response.error != null) {
    yield put(bulkReviewQueryEnvelopeFailure(response.error));
  } else {
    yield put(bulkReviewQueryEnvelopeSuccess());
    yield put(showSuccessAlert('The review job started, you will receive an email shortly'));
  }
}

function* createSampleSaga({ payload }: ReturnType<typeof reviewEnvelope>): SagaReturn {
  yield put(createSampleRequest());

  const resourceParams = (yield select(
    getNavParamsByResource(resourceQueryParamName.envelopes)
  )) as ReturnType<ReturnType<typeof getNavParamsByResource>>;

  const strippedParams = { ...resourceParams };

  let params: Record<string, unknown> = {
    // @ts-ignore
    ...payload,
    include_count: true,
    ...strippedParams,
  };
  if ('filters_search' in strippedParams) {
    params = {
      include_count: true,
      offset: strippedParams.offset,
      filters_search: strippedParams.filters_search,
      order_by: strippedParams.order_by,
      limit: strippedParams.limit,
      order_desc: strippedParams.order_desc,
    };
  }

  if ('states' in strippedParams) {
    strippedParams.event_states = strippedParams.states;
    delete strippedParams.states;
  }

  const response = (yield call([LitLingoClient.resources.envelopes.extras, 'createSample'], {
    data: payload,
    params,
  })) as API.Response<API.Envelopes.CreateSample>;

  if (response.error != null) {
    yield put(createSampleFailure(response.error));
  } else {
    yield put(createSampleSuccess());
    if ('review_set_edit_uuid' in strippedParams) {
      yield put(deleteURLParams(['envelopes__sample_uuid']));
    } else {
      yield put(
        setTree({
          tree: {
            id: v4(),
            op: Operator.AND,
            data: [],
          },
        })
      );
    }
    yield put(addValueToTree({ field: 'sample_uuids', value: response.data.sample_uuid }));
    yield put(showSuccessAlert('Sample created.'));
  }
}

function* reprocessEnvelopeSaga({ payload }: ReturnType<typeof reprocessEnvelope>): SagaReturn {
  yield put(reprocessEnvelopeRequest());

  const { envelopeId, starship } = payload;

  const response = (yield call(
    [LitLingoClient.resources.envelopes.extras, 'reprocessSingleEnvelope'],
    {
      urlParams: { envelopeId },
      data: { starship },
    }
  )) as API.Response<API.Envelopes.ReprocessSingleEnvelope>;

  if (response.error != null) {
    yield put(reprocessEnvelopeFailure(response.error));
  } else {
    yield put(reprocessEnvelopeSuccess());
    window.location.reload();
  }
}

function* addCommentEnvelopeSaga({ payload }: ReturnType<typeof addCommentEnvelope>): SagaReturn {
  yield put(addCommentEnvelopeRequest());

  const { envelopeId, value } = payload;
  const commentData = {
    type: 'comment',
    value,
  };
  const params = {
    relationships: [
      'actions',
      'actions.created_by',
      'events',
      'events.actions',
      'events.campaign',
      'events.rule',
      'communications',
      'created_by.teams.name',
      'tags.tag_value',
      'locked_by',
      'action_summary',
      'reviews',
    ],
    include_pii: 'true',
  };

  const response = (yield call([LitLingoClient.resources.envelopes.extras, 'addAction'], {
    urlParams: { envelopeId },
    params,
    data: commentData,
  })) as API.Response<API.Envelopes.AddAction>;

  if (response.error != null) {
    yield put(addCommentEnvelopeFailure(response.error));
  } else {
    yield put(addCommentEnvelopeSuccess(response.data));
  }
}

function* addNotifyEnvelopeSaga({ payload }: ReturnType<typeof addNotifyEnvelope>): SagaReturn {
  yield put(addNotifyEnvelopeRequest());

  const { envelopeId } = payload;
  const notifyData = {
    type: 'author_notify',
    value: 'notify',
  };

  const params = {
    relationships: [
      'actions',
      'actions.created_by',
      'events',
      'events.actions',
      'events.campaign',
      'events.rule',
      'communications',
      'created_by.teams.name',
      'tags.tag_value',
      'locked_by',
      'action_summary',
      'reviews',
    ],
    include_pii: 'true',
  };

  const response = (yield call([LitLingoClient.resources.envelopes.extras, 'addAction'], {
    urlParams: { envelopeId },
    params,
    data: notifyData,
  })) as API.Response<API.Envelopes.AddAction>;

  if (response.error != null) {
    yield put(addNotifyEnvelopeFailure(response.error));
  } else {
    yield put(addNotifyEnvelopeSuccess(response.data));
  }
}

function* handleBulkReviewEnvelope(action: ReturnType<typeof bulkReviewEnvelope>): SagaReturn {
  const { payload } = action;
  const { envelopeIds, secondsSpent, value, meta_data, currentEnvelope, assignmentId } = payload;

  const finalTags = (yield select(getTags)) as ReturnType<typeof getTags>;
  const envelope = (yield select(getSelectedEnvelope)) as ReturnType<typeof getSelectedEnvelope>;
  const declaredStatus = (yield select(getDeclaredStatus)) as ReturnType<typeof getDeclaredStatus>;

  if (!envelope) return;

  const { tags } = envelope;

  yield put(reviewAndContinueNextEnvelopeRequest());

  // Add missing tags
  const addTags = finalTags?.filter((t) =>
    tags?.every((tag) => tag.tag_value_uuid !== t.tag_value_uuid)
  );
  if (addTags && addTags.length > 0) {
    yield all(
      addTags.map((tag) =>
        call(addBulkEnvelopeTagsSaga, {
          payload: {
            uuids: envelopeIds,
            value: tag?.tag_value?.value || '',
          },
          type: addBulkEnvelopeTags.toString(),
        })
      )
    );
  }

  // Remove tags
  const removeTags = tags?.filter((t) =>
    finalTags?.every((tag) => tag.tag_value_uuid !== t.tag_value_uuid)
  );
  if (removeTags && removeTags.length > 0) {
    yield all(
      removeTags.map((tag) =>
        call(removeBulkEnvelopeTagsSaga, {
          payload: {
            uuids: envelopeIds,
            value: tag?.tag_value?.value || '',
          },
          type: removeBulkEnvelopeTags.toString(),
        })
      )
    );
  }

  // eslint-disable-next-line no-restricted-syntax
  for (const envId of envelopeIds) {
    const env = (yield select((state) => getEnvelope(state, envId))) as ReturnType<
      typeof getEnvelope
    >;
    if (
      declaredStatus &&
      declaredStatus !== 'pending' &&
      (declaredStatus !== env?.review_value || !env.review_value)
    ) {
      yield call(reviewEnvelopeSaga, {
        payload: { envelopeId: envId, secondsSpent, value, meta_data, assignmentId },
        type: reviewEnvelope.toString(),
      });
    }
  }

  yield put(fetchEnvelopeThread({ platformGuid: envelope?.platform_thread_guid as string }));

  yield call(fetchSingleEnvelopeSaga, {
    payload: { envelopeId: currentEnvelope },
    type: reviewEnvelope.toString(),
  });

  yield put(reviewAndContinueNextEnvelopeFulfill());
}

function* handleReviewAndContinueNextEnvelope(
  action: ReturnType<typeof reviewAndContinueNextEnvelope>
): SagaReturn {
  const { payload } = action;
  const { envelopeId, secondsSpent, assignmentId, value, meta_data, nextEnvelopeId } = payload;

  const finalTags = (yield select(getTags)) as ReturnType<typeof getTags>;
  const envelope = (yield select(getSelectedEnvelope)) as ReturnType<typeof getSelectedEnvelope>;
  const declaredStatus = (yield select(getDeclaredStatus)) as ReturnType<typeof getDeclaredStatus>;
  const lastReview = (yield select((state) => getLastReview(state, envelopeId))) as ReturnType<
    typeof getLastReview
  >;

  if (!envelope) return;

  const { tags } = envelope;

  yield put(reviewAndContinueNextEnvelopeRequest());

  // Add missing tags
  const addTags = finalTags?.filter((t) =>
    tags?.every((tag) => tag.tag_value_uuid !== t.tag_value_uuid)
  );
  if (addTags && addTags.length > 0) {
    yield all(
      addTags.map((tag) =>
        call(addEnvelopeTagSaga, {
          payload: {
            envelopeId,
            tag: tag?.tag_value?.value || '',
            skipSaveEnvelope: true,
          },
          type: addEnvelopeTag.toString(),
        })
      )
    );
  }

  // Remove tags
  const removeTags = tags?.filter((t) =>
    finalTags?.every((tag) => tag.tag_value_uuid !== t.tag_value_uuid)
  );
  if (removeTags && removeTags.length > 0) {
    yield all(
      removeTags.map((tag) =>
        call(removeEnvelopeTagSaga, {
          payload: {
            envelopeId,
            tag: tag?.tag_value?.value || '',
            skipSaveEnvelope: true,
          },
          type: removeEnvelopeTag.toString(),
        })
      )
    );
  }

  if (
    declaredStatus &&
    (declaredStatus !== envelope.review_value ||
      meta_data?.is_confirmed !== lastReview?.meta_data.is_confirmed ||
      meta_data?.reviewed_by !== lastReview?.meta_data.reviewed_by)
  ) {
    yield call(reviewEnvelopeSaga, {
      payload: { envelopeId, secondsSpent, assignmentId, value, meta_data },
      type: reviewEnvelope.toString(),
    });
  }
  yield put(fetchEnvelopeThread({ platformGuid: envelope?.platform_thread_guid as string }));

  yield call(fetchSingleEnvelopeSaga, {
    payload: { envelopeId },
    type: reviewEnvelope.toString(),
  });

  if (nextEnvelopeId) {
    const customerDomain = (yield select(getCustomerDomain)) as string;
    const queryParams = ((yield select(getLocation)) as Location).search;
    const path = reverse({
      routeName: 'envelope-detail',
      routeParams: { envelopeId: nextEnvelopeId },
      queryParams,
      customerDomain,
    });

    yield delay(500);

    yield put(push(path));
  }

  yield put(reviewAndContinueNextEnvelopeFulfill());
}

function* fetchEnvelopeAttachmentsSaga(
  action: ReturnType<typeof fetchEnvelopeAttachments>
): SagaReturn {
  const { payload } = action;
  const { envelopeUuid } = payload;

  yield put(fetchEnvelopeAttachmentsRequest);

  const response = (yield call([LitLingoClient.resources.envelopes.extras, 'attachments'], {
    urlParams: { envelopeUuid },
  })) as API.Response<API.Envelopes.Attachments>;

  if (response.error != null) {
    yield put(fetchEnvelopeAttachmentsFailure(response.error));
  } else {
    yield put(fetchEnvelopeAttachmentsSuccess({ attachments: response.data, envelopeUuid }));
  }
  yield put(fetchEnvelopeAttachmentsFulfill);
}

function* fetchFlaggedTextSaga({ payload }: ReturnType<typeof fetchFlaggedText>): SagaReturn {
  const resourceParams = (yield select(
    getNavParamsByResource(resourceQueryParamName.envelopes)
  )) as ReturnType<ReturnType<typeof getNavParamsByResource>>;

  const currentListIds = (yield select(getEnvelopesFlaggedTextListIds)) as ReturnType<
    typeof getEnvelopesFlaggedTextListIds
  >;

  const envelopeUuids = payload.envelopeIds.filter((uuid) => !currentListIds.includes(uuid));
  const tempUuids = [...envelopeUuids];
  yield put(fetchFlaggedTextRequest({ envelopeIds: envelopeUuids }));

  if (envelopeUuids.length === 0) {
    yield put(fetchFlaggedTextFulfill({ envelopeIds: envelopeUuids }));
    return;
  }

  if (resourceParams.broad_search) {
    yield put(fetchFlaggedTextFulfill({ envelopeIds: envelopeUuids }));
    return;
  }

  const response = (yield call([LitLingoClient.resources.envelopes.extras, 'flaggedText'], {
    params: {
      include_count: true,
      envelope_uuids: envelopeUuids,
    },
  })) as API.Response<API.Envelopes.FlaggedText>;

  if (response.error != null) {
    yield put(fetchFlaggedTextFailure(response.error));
  } else {
    yield put(fetchFlaggedTextSuccess(response.data));
  }
  yield put(fetchFlaggedTextFulfill({ envelopeIds: tempUuids }));

  /*  const middleIndex = Math.ceil(envelopeUuids.length / 2);

  const [response1, response2] = (yield all(
    [0, 1].map((el) => {
      const params = {
        include_count: true,
        envelope_uuids:
          el === 0 ? envelopeUuids.splice(0, middleIndex) : envelopeUuids.splice(-middleIndex),
      };

      return call([LitLingoClient.resources.envelopes.extras, 'flaggedText'], {
        params,
      });
    })
  )) as [API.Response<API.Envelopes.FlaggedText>, API.Response<API.Envelopes.FlaggedText>];

  if (response1.error != null) {
    yield put(fetchFlaggedTextFailure(response1.error));
  } else {
    yield put(fetchFlaggedTextSuccess(response1.data));
  }

  if (response2.error != null) {
    yield put(fetchFlaggedTextFailure(response2.error));
  } else {
    yield put(fetchFlaggedTextSuccess(response2.data));
  }
  yield put(fetchFlaggedTextFulfill({ envelopeIds: tempUuids })); */
}

function* fetchMoreLikeThisSaga({ payload }: ReturnType<typeof fetchMoreLikeThis>): SagaReturn {
  const { envelopeUuid, size } = payload;

  yield put(fetchMoreLikeThisRequest());

  const response = (yield call([LitLingoClient.resources.envelopes.extras, 'moreLikeThis'], {
    urlParams: { envelopeUuid },
    params: {
      n: size,
    },
  })) as API.Response<API.Envelopes.MoreLikeThis>;

  if (response.data) {
    yield put(fetchMoreLikeThisFulfill(response.data));
  }
}

function* addAndRemoveValuesToTreeSaga(
  action: ReturnType<typeof addAndRemoveValuesToTree>
): SagaReturn {
  const { valuesToAdd, fieldsToRemove } = action.payload;

  try {
    const tree = cloneDeep((yield select(getTree)) as Tree);

    let newTree = tree;
    valuesToAdd.forEach((valueToAdd) => {
      newTree = setNewValue(
        newTree,
        valueToAdd.field,
        valueToAdd.value,
        valueToAdd.label,
        valueToAdd.isSingleValue
      );
    });

    newTree = removeFields(newTree, fieldsToRemove);

    yield put(
      setURLParams({
        envelopes__filters_search: transformToString(newTree),
        envelopes__offset: '0',
      })
    );
    yield put(setTree({ tree: newTree }));
  } catch (e) {
    yield put(showErrorAlert((e as Error).message));
  }
}

function* addNewValueToTreeSaga(action: ReturnType<typeof addValueToTree>): SagaReturn {
  const { payload } = action;

  try {
    const tree = cloneDeep((yield select(getTree)) as Tree);

    const newTree = setNewValue(
      tree,
      payload.field,
      payload.value,
      payload.label,
      payload.isSingleValue
    );

    yield put(
      setURLParams({
        envelopes__filters_search: transformToString(newTree),
        envelopes__offset: '0',
      })
    );
    yield put(setTree({ tree: newTree }));
  } catch (e) {
    showErrorAlert((e as Error).message);
  }
}

function* removeValueFromTreeSaga(action: ReturnType<typeof removeValueFromTree>): SagaReturn {
  const { payload } = action;
  try {
    const tree = cloneDeep((yield select(getTree)) as Tree);

    const newTree = removeValue(tree, payload.value, payload.field);

    yield put(
      setURLParams({
        envelopes__filters_search: transformToString(newTree),
        envelopes__offset: '0',
      })
    );
    yield put(setTree({ tree: newTree }));
  } catch (e) {
    showErrorAlert((e as Error).message);
  }
}

function* removeValuesFromTreeSaga(action: ReturnType<typeof removeValuesFromTree>): SagaReturn {
  const { payload } = action;
  try {
    const tree = cloneDeep((yield select(getTree)) as Tree);
    let newTree = tree;

    payload.values.forEach((v) => {
      newTree = removeValue(tree, v, payload.field);
    });

    yield put(
      setURLParams({
        envelopes__filters_search: transformToString(newTree),
        envelopes__offset: '0',
      })
    );
    yield put(setTree({ tree: newTree }));
  } catch (e) {
    showErrorAlert((e as Error).message);
  }
}

function* changeConditionalSaga(action: ReturnType<typeof changeConditionalType>): SagaReturn {
  const { payload } = action;

  try {
    const tree = cloneDeep((yield select(getTree)) as Tree);

    const newTree = changeConditional(tree, payload.node, payload.condition);

    yield put(
      setURLParams({
        envelopes__filters_search: transformToString(newTree),
        envelopes__offset: '0',
      })
    );
    yield put(setTree({ tree: newTree }));
  } catch (e) {
    showErrorAlert((e as Error).message);
  }
}

function* changeConditionalByFieldSaga(
  action: ReturnType<typeof changeConditionalByFieldValue>
): SagaReturn {
  const { payload } = action;

  try {
    const tree = cloneDeep((yield select(getTree)) as Tree);

    const newTree = changeConditionalByField(tree, payload.field, payload.condition);

    yield put(
      setURLParams({
        envelopes__filters_search: transformToString(newTree),
        envelopes__offset: '0',
      })
    );
    yield put(setTree({ tree: newTree }));
  } catch (e) {
    showErrorAlert((e as Error).message);
  }
}

function* addValuesToGroupSaga(action: ReturnType<typeof addValuesToGroup>): SagaReturn {
  const { payload } = action;

  try {
    const tree = cloneDeep((yield select(getTree)) as Tree);

    const field = findFieldFromValue(tree, payload.nodes[0].value);
    if (!field.length) return;
    if (!andFilters.includes(`${field}_and`)) return;
    const newTree = addToGroup(tree, payload.nodes);

    yield put(
      setURLParams({
        envelopes__filters_search: transformToString(newTree),
        envelopes__offset: '0',
      })
    );
    yield put(setTree({ tree: newTree }));
    yield put(clearSelectedFilters());
  } catch (e) {
    yield put(clearSelectedFilters());
    showErrorAlert((e as Error).message);
  }
}

function* removeValuesFromGroupSaga(action: ReturnType<typeof removeValuesFromGroup>): SagaReturn {
  const { payload } = action;

  try {
    const tree = cloneDeep((yield select(getTree)) as Tree);

    const newTree = removeFromGroup(tree, payload.nodes);

    yield put(
      setURLParams({
        envelopes__filters_search: transformToString(newTree),
        envelopes__offset: '0',
      })
    );
    yield put(setTree({ tree: newTree }));
    yield put(clearSelectedFilters());
  } catch (e) {
    yield put(clearSelectedFilters());
    showErrorAlert((e as Error).message);
  }
}

function* addFieldsToGroupSaga(action: ReturnType<typeof addFieldsToGroup>): SagaReturn {
  const { payload } = action;

  try {
    const tree = cloneDeep((yield select(getTree)) as Tree);
    const newTree = addFieldToGroup(tree, payload.nodes);

    yield put(
      setURLParams({
        envelopes__filters_search: transformToString(newTree),
        envelopes__offset: '0',
      })
    );
    yield put(setTree({ tree: newTree }));
    yield put(clearSelectedFields());
  } catch (e) {
    yield put(clearSelectedFields());
    yield put(showErrorAlert((e as Error).message));
  }
}

function* removeFieldsFromGroupSaga(action: ReturnType<typeof removeFieldsFromGroup>): SagaReturn {
  const { payload } = action;

  try {
    const tree = cloneDeep((yield select(getTree)) as Tree);

    const newTree = removeFieldFromGroup(tree, payload.nodes);

    yield put(
      setURLParams({
        envelopes__filters_search: transformToString(newTree),
        envelopes__offset: '0',
      })
    );
    yield put(setTree({ tree: newTree }));
    yield put(clearSelectedFields());
  } catch (e) {
    yield put(clearSelectedFields());
    yield put(showErrorAlert((e as Error).message));
  }
}

function* removeFieldFromTreeSaga(action: ReturnType<typeof removeFieldFromTree>): SagaReturn {
  const { payload } = action;

  try {
    const tree = cloneDeep((yield select(getTree)) as Tree);

    const newTree = removeField(tree, payload.field);

    yield put(
      setURLParams({
        envelopes__filters_search: transformToString(newTree),
        envelopes__offset: '0',
      })
    );
    yield put(setTree({ tree: newTree }));
  } catch (e) {
    showErrorAlert((e as Error).message);
  }
}

function* addAllValuesToFieldSaga(action: ReturnType<typeof addAllValuesToField>): SagaReturn {
  const { payload } = action;

  try {
    const tree = cloneDeep((yield select(getTree)) as Tree);

    const newTree = addAllValuesToFieldAction(tree, payload.nodes, payload.field);

    yield put(
      setURLParams({
        envelopes__filters_search: transformToString(newTree),
        envelopes__offset: '0',
      })
    );
    yield put(setTree({ tree: newTree }));
  } catch (e) {
    showErrorAlert((e as Error).message);
  }
}

function* makeSearchonSandboxSaga(action: ReturnType<typeof makeSearchOnSandbox>): SagaReturn {
  const { payload } = action;
  const { sabdboxUUID, ...restPayload } = payload;
  const resourceParams = (yield select(
    getNavParamsByResource(resourceQueryParamName.envelopes)
  )) as ReturnType<ReturnType<typeof getNavParamsByResource>>;
  const strippedParams = { ...resourceParams };

  const actualTree = (yield select(getTree)) as ReturnType<typeof getTree>;

  if ('states' in strippedParams) {
    strippedParams.event_states = strippedParams.states;
    delete strippedParams.states;
  }
  let params: Record<string, string | string[] | boolean> = {
    // @ts-ignore
    ...restPayload,
    ...strippedParams,
  };
  if ('filters_search' in strippedParams) {
    params = {
      include_count: true,
      offset: strippedParams.offset,
      filters_search: strippedParams.filters_search,
      order_by: strippedParams.order_by,
      limit: strippedParams.limit,
      order_desc: strippedParams.order_desc,
    };
    if (
      (strippedParams.created_after || strippedParams.created_before) &&
      !strippedParams.days_from_oor
    ) {
      const fs = addDatesToFiltersSearch(strippedParams, actualTree);
      if (fs) {
        params.filters_search = fs;
      }
    }
    if (strippedParams.sample_uuid) params.sample_uuid = strippedParams.sample_uuid;
  }

  try {
    const response = (yield call(
      [LitLingoClient.resources.envelopes.extras, 'createSearchOnSandbox'],
      {
        urlParams: { sandboxId: sabdboxUUID },
        params,
      }
    )) as API.Response<{ ok: boolean }>;
    if (!response.data || !response.data.ok) {
      yield put(showErrorAlert('Something went wrong while creating search on sandbox'));
    } else {
      yield put(showSuccessAlert('Search created successfully, please be aware of your email'));
    }
  } catch (e) {
    yield put(showErrorAlert('Something went wrong while creating search on sandbox'));
  }
}

function* addEnvelopeToSavedSearchSaga(
  action: ReturnType<typeof addEnvelopeToSavedSearch>
): SagaReturn {
  const { payload } = action;
  const { envelope, savedSearch } = payload;

  const user = (yield select(getUser)) as ReturnType<typeof getUser>;

  let savedSearchParams: RouteParams = {};

  if (user.customer) {
    savedSearchParams = getParamsFromUrl(
      savedSearch.url,
      resourceQueryParamName.envelopes,
      'envelope-list',
      user.customer?.config
    );
  }

  const filterSearch = (savedSearchParams.filters_search ??
    buildFSFromParams(savedSearchParams)) as string;

  try {
    const response = (yield call([LitLingoClient.resources.envelopes.extras, 'searchTree'], {
      params: {
        filters_search: filterSearch,
      },
    })) as API.Response<Tree>;
    if (!response.data) {
      yield put(showErrorAlert('Something went wrong while modifying the assignment'));
    } else {
      let tree: Tree = {
        op: Operator.AND,
        data: [],
        id: v4(),
      };

      if (response.data && response.data.data.length > 0) {
        tree = fillDefaultTree(
          cloneDeep(response.data),
          getNavParamsFromFilters(filterSearch, savedSearchParams)
        );
      }

      let finalTree: Tree = tree;

      const envelopesField = lookForField(tree, 'uuid');
      const fields = getAllFields(tree);

      if (!envelopesField && fields.length > 1) {
        finalTree = addFieldToGroup(tree, fields);
      }
      finalTree = changeConditional(finalTree, finalTree as DataNode, Condition.OR);

      finalTree = setNewValue(finalTree, 'uuid', envelope.uuid, '', false, Condition.OR);
      const finalFs = transformToString(finalTree);

      if (user.customer) {
        const p = getParamsFromUrl(
          savedSearch?.url || '',
          resourceQueryParamName.envelopes,
          'envelope-list',
          user.customer.config
        );

        p.filters_search = finalFs;

        const urlParams = new URLSearchParams();

        Object.entries(p).forEach(([key, value]) => {
          if (Array.isArray(value)) {
            value.forEach((v) => urlParams.append(`envelopes__${key}`, v));
          } else {
            urlParams.append(`envelopes__${key}`, value);
          }
        });

        yield put(
          upsertSavedSearch({
            ...savedSearch,
            url: `?${urlParams.toString()}`,
          })
        );

        const s = (yield take(upsertSavedSearchSuccess)) as ReturnType<
          typeof upsertSavedSearchSuccess
        >;

        yield take(showSuccessAlert);

        yield put(
          showActionAlert({
            actionMsg: 'Undo append',
            id: 'id',
            msg: `${envelope.uuid} added to ${savedSearch.name}`,
            action: removeEnvelopeFromSavedSearch,
            actionPayload: {
              savedSearch: s.payload,
              envelopeId: envelope.uuid,
            },
            timeout: 8000,
          })
        );
      }
    }
  } catch (e) {
    yield put(showErrorAlert('Something went wrong while modifying the assignment'));
  }
}

function* removeEnvelopeFromSavedSearchSaga(
  action: ReturnType<typeof removeEnvelopeFromSavedSearch>
): SagaReturn {
  const { payload } = action;
  const { envelopeId, savedSearch } = payload;

  const user = (yield select(getUser)) as ReturnType<typeof getUser>;

  let savedSearchParams: RouteParams = {};

  if (user.customer) {
    savedSearchParams = getParamsFromUrl(
      savedSearch.url,
      resourceQueryParamName.envelopes,
      'envelope-list',
      user.customer?.config
    );
  }

  const filterSearch = (savedSearchParams.filters_search ??
    buildFSFromParams(savedSearchParams)) as string;

  try {
    const response = (yield call([LitLingoClient.resources.envelopes.extras, 'searchTree'], {
      params: {
        filters_search: filterSearch,
      },
    })) as API.Response<Tree>;
    if (!response.data) {
      yield put(showErrorAlert('Something went wrong while modifying the assignment'));
    } else {
      let tree: Tree = {
        op: Operator.AND,
        data: [],
        id: v4(),
      };

      if (response.data && response.data.data.length > 0) {
        tree = fillDefaultTree(
          cloneDeep(response.data),
          getNavParamsFromFilters(filterSearch, savedSearchParams)
        );
      }

      let finalTree: Tree = tree;

      finalTree = removeValue(finalTree, envelopeId, 'uuid');
      const finalFs = transformToString(finalTree);

      if (user.customer) {
        const p = getParamsFromUrl(
          savedSearch?.url || '',
          resourceQueryParamName.envelopes,
          'envelope-list',
          user.customer.config
        );

        p.filters_search = finalFs;

        const urlParams = new URLSearchParams();

        Object.entries(p).forEach(([key, value]) => {
          if (Array.isArray(value)) {
            value.forEach((v) => urlParams.append(`envelopes__${key}`, v));
          } else {
            urlParams.append(`envelopes__${key}`, value);
          }
        });

        yield put(
          upsertSavedSearch({
            ...savedSearch,
            url: `?${urlParams.toString()}`,
          })
        );
      }
    }
  } catch (e) {
    yield put(showErrorAlert('Something went wrong while modifying the assignment'));
  }
}

function* getModelMetricsSaga(): SagaReturn {
  yield put(getModelMetricsRequest());

  const resourceParams = (yield select(
    getNavParamsByResource(resourceQueryParamName.envelopes)
  )) as ReturnType<ReturnType<typeof getNavParamsByResource>>;

  const response = (yield call([LitLingoClientV2.resources.ModelMetrics, 'list'], {
    params: {
      ...resourceParams,
    },
  })) as API.Response<APIV2.ModelMetrics.List>;
  if (response.error != null) {
    yield put(getModelMetricsFailure(response.error));
  } else {
    yield put(getModelMetricsSuccess(response.data));
  }
}

function* createModelMetricsSaga(action: ReturnType<typeof createModelMetrics>): SagaReturn {
  const { payload } = action;
  yield put(createModelMetricsRequest());

  const response = (yield call([LitLingoClientV2.resources.ModelMetrics, 'upsert'], {
    data: payload,
  })) as API.Response<APIV2.ModelMetrics.Upsert>;
  if (response.error != null) {
    yield put(createModelMetricsFailure(response.error));
  } else {
    yield put(createModelMetricsSuccess(response.data));
    yield put(showSuccessAlert(`Model metrics created`));
  }
}

function* reprocessModelMetricsSaga({
  payload,
}: ReturnType<typeof reprocessModelMetrics>): SagaReturn {
  const { starship, query } = payload;
  yield put(reprocessModelMetricsRequest());

  const params: Record<string, unknown> = {
    include_count: true,
    limit: 10000,
    ...query,
  };

  const response = (yield call([LitLingoClient.resources.customers.extras, 'reprocessWithFilter'], {
    params,
    data: { starship },
  })) as API.Response<API.Customers.ReprocessWithFilter>;
  if (response.error != null) {
    yield put(reprocessModelMetricsFailure(response.error));
  } else {
    yield put(reprocessModelMetricsSuccess());
    yield put(showSuccessAlert('Reprocess started successfully'));
  }
}

function* envelopesSaga(): SagaReturn {
  yield takeLatest(fetchMoreLikeThis.toString(), fetchMoreLikeThisSaga);
  yield takeLatest(exportEnvelopesSearch.toString(), exportEnvelopesSearchSaga);
  yield takeLatest(exportSingleEnvelope.toString(), exportSingleEnvelopesSaga);
  yield takeLatest(exportNgram.toString(), exportNgramSaga);
  yield takeLatest(extractSenderDomains.toString(), extractSenderDomainsSaga);
  yield takeLatest(extractRecipientDomains.toString(), extractRecipientDomainsSaga);
  yield takeLatest(reprocessBulk.toString(), reprocessBulkSaga);
  yield takeLatest(extractBulk.toString(), extractBulkSaga);
  yield takeLatest(fetchAllEnvelopes.toString(), fetchAllEnvelopesSaga);
  yield takeEvery(fetchAggs.toString(), fetchAggsSaga);
  yield takeLatest(fetchAllEnvelopesForSample.toString(), fetchAllEnvelopesForSampleSaga);
  yield takeLatest(setHasEventsFilter.toString(), setHasEventsFilterSaga);
  yield takeLatest(fetchSingleEnvelope.toString(), fetchSingleEnvelopeSaga);
  yield takeLatest(fetchEnvelopeThread.toString(), fetchEnvelopeThreadSaga);
  yield takeLatest(starEnvelope.toString(), starEnvelopeSaga);
  yield takeLatest(markEnvelopeAsRead.toString(), markEnvelopeAsReadSaga);
  yield takeLatest(addEnvelopeTag.toString(), addEnvelopeTagSaga);
  yield takeLatest(removeEnvelopeTag.toString(), removeEnvelopeTagSaga);
  yield takeEvery(addBulkEnvelopeTags.toString(), addBulkEnvelopeTagsSaga);
  yield takeEvery(removeBulkEnvelopeTags.toString(), removeBulkEnvelopeTagsSaga);
  yield takeEvery(addBulkAllEnvelopeTags.toString(), addBulkAllEnvelopeTagsSaga);
  yield takeEvery(removeBulkAllEnvelopeTags.toString(), removeBulkAllEnvelopeTagsSaga);
  yield takeLatest(fetchEnvelopeEsDocs.toString(), fetchEnvelopeEsDocsSaga);
  yield takeLatest(fetchEnvelopeEsDocsAsIndexed.toString(), fetchEnvelopeEsDocsAsIndexedSaga);
  yield takeLatest(addActionsBulkEnvelope.toString(), addActionsBulkEnvelopeSaga);
  yield takeEvery(reviewEnvelope.toString(), reviewEnvelopeSaga);
  yield takeLatest(createSample.toString(), createSampleSaga);
  yield takeLatest(reprocessEnvelope.toString(), reprocessEnvelopeSaga);
  yield takeLatest(addCommentEnvelope.toString(), addCommentEnvelopeSaga);
  yield takeLatest(addNotifyEnvelope.toString(), addNotifyEnvelopeSaga);
  yield debounce(300, fetchFlaggedText.toString(), fetchFlaggedTextSaga);
  yield takeEvery(reviewAndContinueNextEnvelope.toString(), handleReviewAndContinueNextEnvelope);
  yield takeLatest(bulkReviewEnvelope.toString(), handleBulkReviewEnvelope);
  yield takeLatest(fetchEnvelopeAttachments.toString(), fetchEnvelopeAttachmentsSaga);
  yield takeLatest(addValueToTree.toString(), addNewValueToTreeSaga);
  yield takeLatest(removeValueFromTree.toString(), removeValueFromTreeSaga);
  yield takeLatest(removeValuesFromTree.toString(), removeValuesFromTreeSaga);
  yield takeLatest(addAndRemoveValuesToTree.toString(), addAndRemoveValuesToTreeSaga);
  yield takeLatest(changeConditionalType.toString(), changeConditionalSaga);
  yield takeLatest(addValuesToGroup.toString(), addValuesToGroupSaga);
  yield takeLatest(removeValuesFromGroup.toString(), removeValuesFromGroupSaga);
  yield takeLatest(addFieldsToGroup.toString(), addFieldsToGroupSaga);
  yield takeLatest(removeFieldsFromGroup.toString(), removeFieldsFromGroupSaga);
  yield takeLatest(removeFieldFromTree.toString(), removeFieldFromTreeSaga);
  yield takeLatest(addAllValuesToField.toString(), addAllValuesToFieldSaga);
  yield takeLatest(makeSearchOnSandbox.toString(), makeSearchonSandboxSaga);
  yield takeLatest(changeConditionalByFieldValue.toString(), changeConditionalByFieldSaga);
  yield takeLatest(bulkReviewEnvelopes.toString(), bulkReviewEnvelopesSaga);
  yield takeLatest(bulkReviewQueryEnvelope.toString(), bulkReviewQueryEnvelopeSaga);
  yield takeLatest(addEnvelopeToSavedSearch.toString(), addEnvelopeToSavedSearchSaga);
  yield takeLatest(removeEnvelopeFromSavedSearch.toString(), removeEnvelopeFromSavedSearchSaga);
  yield takeLatest(getModelMetrics.toString(), getModelMetricsSaga);
  yield takeLatest(createModelMetrics.toString(), createModelMetricsSaga);
  yield takeLatest(reprocessModelMetrics.toString(), reprocessModelMetricsSaga);
}

export default envelopesSaga;

import { upsertTag } from 'actions';
import SelectRedesign from 'components/Select/SelectRedesign';
import { RELOAD_ICON } from 'constants/commonIcons';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { getUser } from 'selectors/auth';
import type { Tag } from 'types';

type ComponentProps = {
  tag: Omit<Tag, 'uuid'> & { uuid?: Tag['uuid'] };
  toggleForm: boolean;
  setToggleForm: React.Dispatch<React.SetStateAction<boolean>>;
};
const TagForm: React.FC<ComponentProps> = (props) => {
  const { tag, toggleForm, setToggleForm } = props;

  const dispatch = useDispatch();

  const [type, setType] = React.useState<string>('');

  const user = useSelector(getUser);

  const { register, reset, watch, setValue, getValues } = useForm({
    defaultValues: { value: tag.value, description: tag.description, color: tag.color },
  });

  const tagTypeOptions = [
    { label: 'Normal', value: 'human' },
    { label: 'Modeler', value: 'modeler' },
  ];

  useEffect(() => {
    reset({ value: tag.value, description: tag.description, color: tag.color });
  }, [tag, reset]);

  const handleRandomColor = (): void => {
    setValue('color', `#${Math.floor(Math.random() * 16777215).toString(16)}`); // ✅ performant
  };

  const handleSubmitTagForm = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    e.preventDefault();
    dispatch(
      upsertTag({
        ...(tag.uuid ? { uuid: tag.uuid } : {}),
        type,
        ...getValues(),
      })
    );
    setToggleForm(false);
  };

  return (
    <div
      className={`litlingo-gray-bg-color overflow-hidden transition-all duration-500 ease-in-out ${
        toggleForm ? 'max-h-96 p-4 border rounded-lg' : 'max-h-0 p-0'
      }`}
    >
      <h2 className="text-xl font-bold leading-5 text mb-6">Create or edit a tag</h2>
      <form>
        <div className="flex justify-between flex-wrap">
          <div className="w-2/5">
            <label htmlFor="value" className="block text-sm font-bold leading-5 text">
              {'Name '}
              <span className="litlingo-red-color">*</span>
              <div className="mt-1 rounded-md shadow-sm">
                <input
                  id="value"
                  name="value"
                  ref={register}
                  className="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 litlingo-gray-bg-color"
                />
              </div>
            </label>
          </div>
          <div className="w-2/5">
            <label htmlFor="description" className="block text-sm font-bold leading-5 text">
              {'Description '}
              <div className="mt-1 rounded-md shadow-sm">
                <textarea
                  id="description"
                  name="description"
                  ref={register}
                  rows={3}
                  className="form-textarea block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 litlingo-gray-bg-color"
                />
              </div>
            </label>
          </div>
          <div className="w-2/5 mt-2">
            <label htmlFor="color" className="block text-sm font-bold leading-5 text">
              {'Color '}
              <span className="litlingo-red-color">*</span>
              <div className="mt-1 rounded-md shadow-sm flex items-center">
                <button
                  type="button"
                  onClick={handleRandomColor}
                  style={{ backgroundColor: `${watch('color')}` }}
                  className="w-6 h-6 mr-2 flex justify-center items-center rounded text-white focus:outline-none"
                >
                  {RELOAD_ICON}
                </button>
                <input
                  id="color"
                  name="color"
                  ref={register}
                  className="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 litlingo-gray-bg-color"
                />
              </div>
            </label>
          </div>

          {
            // @ts-ignore
            user.roles.includes('super-admin') && (
              <div className="w-2/5 mt-2">
                <span className="w-30 block text-sm font-bold leading-5 text">
                  {'Type '}
                  <SelectRedesign
                    onChange={(value: string): void => setType(value)}
                    options={tagTypeOptions}
                    value={type}
                    placeholder="Type"
                    className="mt-1"
                  />
                </span>
              </div>
            )
          }
        </div>
        <button onClick={handleSubmitTagForm} type="submit" className="button button--primary mt-6">
          Save
        </button>
      </form>
    </div>
  );
};

export default TagForm;

import {
  fetchAllModelsRequest,
  fetchModelCategoriesRequest,
  fetchSingleModelRequest,
  promoteAllRequest,
  upsertModelRequest,
} from 'actions/models';
import type { Category, MModel, NormalizedResource, Selector, UUID } from 'types';

export const getFetchAllModelsLoading: Selector<boolean> = (state) =>
  state.models.loading.includes(fetchAllModelsRequest.toString());

export const getFetchSingleModelLoading: Selector<boolean> = (state) =>
  state.models.loading.includes(fetchSingleModelRequest.toString());

export const getUpsertModelLoading: Selector<boolean> = (state) =>
  state.models.loading.includes(upsertModelRequest.toString());

export const getFetchModelCategoriesLoading =
  (id: UUID): Selector<boolean> =>
  (state): boolean =>
    state.models.loading.includes(`${fetchModelCategoriesRequest.toString()}-${id}`);

export const getModel =
  (id: UUID): Selector<MModel> =>
  (state): MModel =>
    state.models.models[id];

export const getAllModels: Selector<NormalizedResource<MModel>> = (state) => state.models.models;

export const getModelCategories: Selector<Category[], [UUID]> = (state, id) => {
  const model = getModel(id)(state);
  if (!model || !model.categories || !model.categories.length) {
    return [];
  }

  return [...new Set(model.categories)]; // skip duplicates
};

export const getModelsList: Selector<MModel[]> = (state) => Object.values(state.models.models);

export const getActiveModel: Selector<UUID> = (state) => state.models.activeModel;

export const getModelsTotalCount: Selector<number> = (state) => state.models.count;

export const getPromoteAllLoading: Selector<boolean> = (state) =>
  state.models.loading.includes(promoteAllRequest.toString());

/* eslint-disable camelcase */

import ModelManagerTable from 'components/RuleGroupManagerTable';
import RuleManagerAction from 'components/RuleGroupManagerTable/RuleManagerAction';
import RuleManagerControls from 'components/RuleGroupManagerTable/RuleManagerControls';
import SelectRedesign from 'components/Select/SelectRedesign';
import WarningMsg from 'components/WarningMsg';
import React, { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { getAddedNodes, getModifiedNodes, getSelectedNode } from 'selectors/config';
import { getNavParams } from 'selectors/nav';
import { getConfigRule, getShouldDisable, saveRuleRequestLoading } from 'selectors/ruleGroup';
import { useSelector } from 'store';
import type { MRuleRevision } from 'types';
import { sectionNotficationMesgs } from 'utils/userNotificationMsgs';
import RuleManagerDiscardChangesButton from './RuleManagerDiscardChangesButton';
import RuleManagerSaveButton from './RuleManagerSaveButton';

type ComponentProps = {
  rule: MRuleRevision;
  toggleShowModal: () => void;
};

const ModelConfigSection: React.FC<ComponentProps> = ({ rule, toggleShowModal }) => {
  const [idToMove, setIdToMove] = useState<string | null>(null);
  const [collapseLevel, setCollapseLevel] = useState<number>(-1);
  const [, setIsCollapsedAll] = useState<boolean>();
  const [collapsedFolder, setCollapsedFolder] = useState<string[]>([]);
  const [collapsedItems, setCollapsedItems] = useState<string[]>([]);
  const [isLevelModified, setLevelIsModified] = useState(false);

  const modifiedNodes = useSelector(getModifiedNodes);
  const addedNodes = useSelector(getAddedNodes);
  const arrayTree = useSelector(getConfigRule);

  const { highlighted_rule } = useSelector(getNavParams) as {
    highlighted_rule?: string;
  };
  const selectedNode = useSelector(getSelectedNode);
  const isLoadingSaveRule = useSelector(saveRuleRequestLoading);
  const shouldDisable = useSelector(getShouldDisable);

  let level = 0;
  arrayTree.forEach((n) => {
    if (n.level > level) {
      level = n.level;
    }
  });

  useEffect(() => {
    const node = arrayTree.find((n) => n.id === highlighted_rule);
    if (node) {
      setCollapseLevel(node?.level);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [highlighted_rule]);

  useEffect(() => {
    if (arrayTree) {
      setCollapseLevel(2);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeCollapseLevel = (value: string): void => {
    setCollapseLevel(Number(value));
  };

  const levelOptions = (): {
    label: string;
    value: string;
    className?: string;
  }[] => {
    const options = [];

    for (let i = 1; i < level; i += 1) {
      options.push({
        label: `Level ${i}`,
        value: i.toString(),
      });
    }

    options.push({
      label: `Show All`,
      value: level.toString(),
    });

    if (isLevelModified) {
      options.unshift({
        label: '-',
        value: (-1).toString(),
      });
    }

    return options;
  };

  const renderRuleManagerSecondaryActions = (
    <div className="w-25">
      <SelectRedesign
        className="bg-white"
        optionClassname="bg-white"
        placeholder="Show All"
        options={levelOptions()}
        onChange={handleChangeCollapseLevel}
        value={collapseLevel === level ? '' : collapseLevel.toString()}
      />
    </div>
  );

  const renderRuleManagerActions = (
    <div className="flex flex-row gap-2">
      {(modifiedNodes.length > 0 || addedNodes.length > 0) && <RuleManagerDiscardChangesButton />}
      <RuleManagerSaveButton rule={rule} />
    </div>
  );

  const handlePrintComments = (): void => {
    let commentString = '';
    arrayTree.forEach((i) => {
      const space = '  ';
      if (i.description) {
        commentString += `${space.repeat(i.level)}${i.description}\n`;
      }
    });
    // eslint-disable-next-line no-console
    console.log(commentString);
  };

  const loadingSkeleton = (): JSX.Element => (
    <div>
      <Skeleton count={3} />
    </div>
  );

  return (
    <div className="flex flex-col gap-2">
      <div
        className="flex flex-col bg-litlingo-white sticky top-0 z-50"
        style={{ boxShadow: '4px 6px 18px 0px rgba(0, 0, 0, 0.10)' }}
      >
        <div className="h-13 px-6 flex flex-row items-center border-b border-t border-litlingo-gray-2">
          {renderRuleManagerSecondaryActions}

          <div className="flex-1">
            {arrayTree && selectedNode != null && arrayTree[selectedNode] && (
              <RuleManagerControls
                selectedId={arrayTree[selectedNode].id}
                idToMove={idToMove}
                arrayTree={arrayTree}
                setIsCollapsedAll={setIsCollapsedAll}
              />
            )}
          </div>
          {renderRuleManagerActions}
        </div>
        {arrayTree && selectedNode != null && arrayTree[selectedNode] && (
          <div className="h-10 px-6 flex flex-row items-center bg-litlingo-gray-0.5 border-b border-litlingo-gray-2">
            <RuleManagerAction
              setIdToMove={setIdToMove}
              selectedId={arrayTree[selectedNode].id}
              idToMove={idToMove}
              arrayTree={arrayTree}
              toggleShowModal={toggleShowModal}
              setIsCollapsedAll={setIsCollapsedAll}
            />
          </div>
        )}
      </div>
      <div className="h-7 px-6 flex flex-row justify-between">
        <div>
          {!shouldDisable && (
            <WarningMsg
              classes=""
              message={sectionNotficationMesgs.unsavedChanges}
              showWarning={modifiedNodes.length > 0 || addedNodes.length > 0}
              showLoading={isLoadingSaveRule}
              showSavedMsg
            />
          )}
        </div>
        <div>
          <span>Hits</span>
        </div>
      </div>

      {rule && rule.identifiers ? (
        <ModelManagerTable
          setIdToMove={setIdToMove}
          idToMove={idToMove}
          collapseLevel={collapseLevel}
          setLevelIsModified={setLevelIsModified}
          setCollapseLevel={setCollapseLevel}
          collapsedFolder={collapsedFolder}
          setCollapsedFolder={setCollapsedFolder}
          collapsedItems={collapsedItems}
          setCollapsedItems={setCollapsedItems}
          setIsCollapsedAll={setIsCollapsedAll}
        />
      ) : (
        <div className="p-4">{loadingSkeleton()}</div>
      )}
      <div className="mt-4 px-6 pb-2 flex flex-row justify-end">
        <button
          type="button"
          className="button button--secondary font-bold h-8 whitespace-no-wrap"
          onClick={handlePrintComments}
        >
          Print Comments
        </button>
      </div>
    </div>
  );
};

export default ModelConfigSection;

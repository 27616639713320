/* eslint-disable max-lines */
/* eslint-disable camelcase */
import { patchSingleRuleConfig, showSuccessAlert } from 'actions';
import { setDropPosition } from 'actions/identifier';
import { annotatorPosition, setToAnnotator } from 'actions/ruleGroup';
import LinkLookup from 'components/LinkLookup';
import Tooltip from 'components/Tooltip';
import {
  ALERT_ICON,
  ARROW_CLOSE_ENVELOPE,
  ARROW_OPEN_ENVELOPE,
  LINK_ICON,
} from 'constants/envelopeIcons';
import itemRowcolors, { bgColorsUtilization } from 'constants/rowItem';
import { NOTES_ICON_REDESIGN } from 'constants/testCaseListIcons';
import moment from 'moment';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import type { DragElementWrapper, DragPreviewOptions, DragSourceOptions } from 'react-dnd';
import { DragPreviewImage } from 'react-dnd';
import { useDispatch } from 'react-redux';
import {
  getAddedNodes,
  getCompareMode,
  getDisabledNode,
  getLastChangedNode,
  getModifiedNodes,
  getSelectedCustomerRuleConfig,
  getSelectedNode,
  getUtilization,
  makeGetAllChildNodes,
  makeGetAllChildNodesAndRelationships,
  makeGetScopedCustomers,
} from 'selectors/config';
import { getDropPosition } from 'selectors/identifier';
import { getNavParamsCreate } from 'selectors/nav';
import {
  getFetchRuleCustomersLoading,
  getRuleGroupLoading,
  getSelectedIdentifiers,
  getSelectedRule,
  getSelectedRuleIdentifiers,
  getShouldDisable,
  getShowRuleUtilization,
  getShowV2Graph,
  getTriggeredNodes,
  getTriggeredNodesV2,
} from 'selectors/ruleGroup';
import {
  getBranchHits,
  getHeatMapColor,
  getShowRuleHeatmap,
  getShowRuleHighlights,
} from 'selectors/ruleGroups';
import { useSelector } from 'store';
import type { RuleRowDataType } from 'types';
import {
  formattedName,
  indentation,
  modifiedIcon,
  operatorName,
  operatorNameNot,
  previewImage,
  relationshipIcon,
} from './RuleManagerUtils';

type ComponentProps = {
  data: RuleRowDataType;
  isDragging: boolean;
  active: boolean;
  drag: DragElementWrapper<DragSourceOptions>;
  drop: DragElementWrapper<unknown>;
  preview: DragElementWrapper<DragPreviewOptions>;
  collapsedItems: string[];
  collapsedFolder: string[];
  idToMove: string | null;
  handleUncollapse: (id: string) => void;
  handleCollapse: (id: string, isRoot?: boolean) => void;
  showTransition: boolean;
};

const RowItem: React.FC<ComponentProps> = ({
  data,
  isDragging,
  active,
  drag,
  drop,
  preview,
  collapsedItems,
  collapsedFolder,
  idToMove,
  handleUncollapse,
  handleCollapse,
  showTransition,
}) => {
  const dispatch = useDispatch();

  let { name } = data;
  const { deleted } = data;
  const {
    id,
    actionItem,
    level,
    relationship,
    annotatorId,
    index,
    identifierId,
    changed,
    change,
    original,
  } = data;

  const cleanId = id.replace('-original', '');

  const triggeredNodes = useSelector(getTriggeredNodes);
  const showV2 = useSelector(getShowV2Graph);
  const triggeredNodesV2 = useSelector(getTriggeredNodesV2);
  const modifiedNodes = useSelector(getModifiedNodes);
  const addedNodes = useSelector(getAddedNodes);
  const lastChangedNode = useSelector(getLastChangedNode);
  const disabled = useSelector((state) => getDisabledNode(state, id));
  const rule = useSelector(getSelectedRule);
  const selectedNode = useSelector(getSelectedNode);
  const showHighlights = useSelector(getShowRuleHighlights);
  const { highlighted_rule } = useSelector(getNavParamsCreate) as {
    highlighted_rule?: string;
  };

  const selectScopedCustomers = useMemo(makeGetScopedCustomers, []);
  const scopedCustomers = useSelector((state) => selectScopedCustomers(state, cleanId));

  const selectAllChildNodesAndRelationships = useMemo(makeGetAllChildNodesAndRelationships, []);
  const allChildNodes = useSelector((state) => selectAllChildNodesAndRelationships(state, cleanId));

  const selectAllChilds = useMemo(makeGetAllChildNodes, []);
  const allChilds = useSelector((state) => selectAllChilds(state, cleanId));

  const selectedCustomer = useSelector(getSelectedCustomerRuleConfig);

  const utilization = useSelector((state) => getUtilization(state, cleanId));
  const showUtilization = useSelector(getShowRuleUtilization);

  const ruleCustomersLoading = useSelector(getFetchRuleCustomersLoading);
  const ruleGroupLoading = useSelector(getRuleGroupLoading);
  const utilizationLoading = ruleGroupLoading || ruleCustomersLoading;

  const showHeatmap = useSelector(getShowRuleHeatmap);
  const hits = useSelector((state) => getBranchHits(state, rule?.rule_group_uuid || '', cleanId));

  const heatColor = useSelector((state) =>
    getHeatMapColor(state, rule?.rule_group_uuid || '', cleanId)
  );

  const node = useSelector(
    (state) => state.config.items[cleanId] || state.config.compareItems[cleanId]
  );
  const isRoot = node.parent == null;

  const selectedAnnotators = useSelector(getSelectedIdentifiers);
  const dropPosition = useSelector(getDropPosition);

  const identifiers = useSelector(getSelectedRuleIdentifiers);
  const compareMode = useSelector(getCompareMode);
  const shouldDisable = useSelector(getShouldDisable);

  const [hover, setHover] = useState(false);
  const [inputFocused, setInputFocused] = useState(false);
  const [image, setImage] = useState('');
  const [isSetColorOpened, setIsSetColorOpened] = useState(false);
  const [description, setDescription] = useState(data.description);
  const ref = useRef(null);
  const span = useRef<HTMLInputElement>(null);
  const container = useRef<HTMLDivElement>(null);
  const containerP = useRef<HTMLDivElement>(null);

  const [width, setWidth] = useState(5);

  const numberOfcollapsedItems = allChildNodes.reduce((acc, n) => {
    let count = 0;
    if (n) {
      const compareId = id.includes('original') ? `${n.id}-original` : n.id;

      if (collapsedItems.includes(compareId)) {
        count += 1;
      }

      if ('relationship' in n && collapsedFolder.includes(compareId)) {
        count += n.relationship.length;
      }
    }
    return acc + count;
  }, 0);

  useEffect(() => {
    const position = index + allChilds.length - numberOfcollapsedItems;
    if (active) {
      dispatch(setDropPosition(position));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active, id]);

  // calcultes the width of description input
  useEffect(() => {
    const input = span.current;
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    if (context && input) {
      context.font = window.getComputedStyle(input).font;
      const metrics = context.measureText(input.value);
      setWidth(metrics.width + 10);
    }
  }, [description]);

  useEffect(() => {
    setImage(previewImage(name).src);
  }, [name]);

  /* useEffect(() => {
    if (triggeredNodes.includes(id) && showHighlights) {
      if (!isRoot && node.parent) {
        handleUncollapse(node.parent);
      } else {
        handleUncollapse(id);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggeredNodes.length, showHighlights]); */

  drag(drop(ref));

  const determineNotScopedIdentifier = (): boolean => {
    if (node.typeOfConfig === 'ANNOTATION_MATCH' && node.annotatorId) {
      const identifier = identifiers.find((ann) => ann.identifier_uuid === node.annotatorId);
      if (identifier?.identifier?.language_matchers) {
        const matchers = identifier?.identifier?.language_matchers.filter(
          (lm) => 'customer_uuids' in lm && lm.customer_uuids && lm.customer_uuids?.length > 0
        );

        if (matchers.length === 0) return false;

        if (!matchers.every((lm) => scopedCustomers.every((c) => lm.customer_uuids?.includes(c)))) {
          return true;
        }
      }
    }
    return false;
  };

  const getBackgroundColor = (): string => {
    if (changed === 'add') return 'bg-litlingo-success-light';
    if (changed === 'delete') return 'bg-litlingo-alert-light';
    if (changed === 'change' && original) {
      return 'bg-litlingo-alert-light';
    }
    if (changed === 'change' && change !== 'identifier_revision') {
      return 'bg-litlingo-success-light';
    }

    if (utilization && showUtilization && !utilizationLoading) {
      return bgColorsUtilization[utilization];
    }

    if (idToMove === id) return 'bg-litlingo-gray-2';
    if (isDragging) return 'opacity-50 bg-litlingo-gray-1';
    if (active) return 'border-2 border-litlingo-primary-80';
    if (highlighted_rule === id) return 'bg-litlingo-gray-2';

    return 'bg-white';
  };

  if (data.modifiers?.NOT ?? false) {
    name = operatorNameNot[name as 'RELATIONSHIP_MATCH' | 'OR' | 'AND'];
  }
  if (data.negated) {
    name = `${name} (NEGATED)`;
  } else if (data.negated === false) {
    name = `${name} (NOT NEGATED)`;
  }

  if (data.mode_of_speech === 'subject') {
    name = `${name} (SUBJECT)`;
  } else if (data.mode_of_speech === 'object') {
    name = `${name} (OBJECT)`;
  }

  const modifyDescription = (value: string): void => {
    setDescription(value);
    dispatch(
      // @ts-ignore
      patchSingleRuleConfig({
        id: data.id,
        description: value,
      })
    );
  };

  const modifyColor = (value: string): void => {
    dispatch(
      // @ts-ignore
      patchSingleRuleConfig({
        id: data.id,
        color: value,
      })
    );
  };

  const renderRelationship = (): JSX.Element[] => {
    const getRelationshipBackgroundColor = (): string => {
      if (changed === 'add') return 'bg-litlingo-success-light';
      if (changed === 'delete') return 'bg-litlingo-alert-light';
      if (changed === 'change' && original) {
        return 'bg-litlingo-alert-light';
      }
      if (changed === 'change') return 'bg-litlingo-success-light';

      if (utilization && showUtilization) {
        return bgColorsUtilization[utilization];
      }

      return 'bg-white';
    };

    return relationship.map((item) => (
      <Tooltip tooltip={disabled ? 'ruleConfig.disabledBranch' : ''} key={item.id}>
        <div
          className={`relative flex flex-row items-center justify-between w-full border-litlingo-gray-2 px-6
            ${getRelationshipBackgroundColor()}
            ${collapsedItems.includes(id) ? 'hidden' : ''}`}
        >
          {selectedCustomer && scopedCustomers.includes(selectedCustomer) && !compareMode && (
            <div className="absolute top-0 bottom-0 left-0 right-0 bg-litlingo-success-light bg-opacity-50" />
          )}
          <div className="relative whitespace-no-wrap text-body p-1  text-left flex flex-row">
            <div className="flex flex-row">
              <div
                className={` mr-2 text-right w-6 ${
                  disabled ? 'text-litlingo-gray-3' : 'text-litlingo-gray-6'
                }`}
              >
                {index}
              </div>
              <div>{actionItem}</div>
            </div>
            {indentation.repeat(level)}
            {'\u00A0\u00A0'}
            {relationshipIcon(disabled, utilization === 'none')}
            <div className="relative">
              {item.deleted ? (
                <span className="text-gray-500">
                  {formattedName(item.name, disabled, false, utilization === 'none')}
                </span>
              ) : (
                formattedName(item.name, disabled, false, utilization === 'none')
              )}
            </div>
          </div>
          <span className="relative ml-2.5 border-b border-litlingo-gray-1 w-full" />
          <div className="relative pr-4" key={item.id}>
            {modifiedNodes && modifiedNodes.indexOf(item.id) !== -1 ? modifiedIcon : ''}
          </div>
        </div>
      </Tooltip>
    ));
  };

  const handleClick = (): void => {
    if (collapsedFolder.includes(id)) {
      handleUncollapse(id);
    } else {
      handleCollapse(id);
    }
  };

  const renderName = (itemId: string): JSX.Element | string => {
    const getChangeColor = (): string => {
      if (original) {
        return 'bg-litlingo-alert-medium';
      }
      return 'bg-litlingo-primary-60';
    };

    const highlightIdentifier = (): boolean => {
      if (
        node.typeOfConfig === 'ANNOTATION_MATCH' &&
        node.annotatorId &&
        selectedAnnotators.includes(node?.annotatorId)
      ) {
        return true;
      }
      return false;
    };

    const identifierBgColor = (): string => {
      if (node.typeOfConfig === 'ANNOTATION_MATCH' && node.annotatorId) {
        if (selectedAnnotators.includes(node?.annotatorId)) {
          if (compareMode) {
            if (changed === 'delete') {
              return 'bg-litlingo-alert text-white';
            }
            if (changed === 'add') {
              return 'bg-litlingo-success text-white';
            }

            return 'bg-litlingo-gray-4 text-white';
          }
          return 'bg-litlingo-success text-white';
        }

        if (compareMode && changed === 'change' && change === 'identifier_revision') {
          return 'bg-litlingo-highlight';
        }
      }
      return '';
    };

    if (annotatorId) {
      if (deleted) {
        return (
          <Tooltip tooltip="ruleConfig.deletedAnnotator">
            <span
              className={`text-gray-500 ${
                showHighlights &&
                !compareMode &&
                ((!showV2 && triggeredNodes.includes(id)) ||
                  (showV2 && triggeredNodesV2.includes(id)))
                  ? 'bg-litlingo-highlight'
                  : ''
              }`}
            >
              {formattedName(name, disabled, false, utilization === 'none')}
            </span>
          </Tooltip>
        );
      }
      if (!identifierId) {
        return (
          <span
            className={`relative mr-2.5 ${identifierBgColor()}  ${
              showHighlights &&
              !compareMode &&
              ((!showV2 && triggeredNodes.includes(id)) ||
                (showV2 && triggeredNodesV2.includes(id)))
                ? 'bg-litlingo-highlight'
                : ''
            }`}
          >
            {formattedName(name, disabled, highlightIdentifier(), utilization === 'none')}
          </span>
        );
      }
      return (
        <LinkLookup
          routeName="global-identifier-manager"
          routeParams={{ identifierId: identifierId || '' }}
          className={`relative flex flex-row mr-2.5 hover:underline ${identifierBgColor()} ${
            disabled ? 'text-litlingo-gray-3' : 'text-litlingo-gray-6'
          } ${
            showHighlights &&
            !compareMode &&
            ((!showV2 && triggeredNodes.includes(id)) || (showV2 && triggeredNodesV2.includes(id)))
              ? 'bg-litlingo-highlight'
              : ''
          }`}
          onClick={(): void => {
            dispatch(annotatorPosition(node.id));
            dispatch(setToAnnotator(true));
          }}
        >
          {formattedName(name, disabled, highlightIdentifier(), utilization === 'none')}
          {determineNotScopedIdentifier() && (
            <div className="flex flex-row gap-0.5 pl-1"> ( {ALERT_ICON} )</div>
          )}
        </LinkLookup>
      );
    }

    return (
      <div
        className={`relative flex items-center ${
          showHighlights &&
          !compareMode &&
          ((!showV2 && triggeredNodes.includes(id)) || (showV2 && triggeredNodesV2.includes(id)))
            ? 'bg-litlingo-highlight'
            : ''
        }`}
      >
        {!collapsedFolder.includes(itemId) && (
          <span
            className={`absolute border-l border-litlingo-gray-2 left-1.5 top-6 `}
            style={{
              height: `${(allChildNodes.length - numberOfcollapsedItems) * 28}px`,
            }}
          />
        )}
        {operatorName[name as keyof typeof operatorName] && !isRoot && (
          <button
            data-testid={`collapse-${itemId}`}
            className="focus:outline-none mr-1"
            type="button"
            onClick={handleClick}
          >
            {collapsedFolder.includes(id)
              ? ARROW_CLOSE_ENVELOPE(disabled)
              : ARROW_OPEN_ENVELOPE(disabled)}
          </button>
        )}
        <span className={`${change === 'operator' && getChangeColor()}`}>
          {formattedName(name, disabled, false, utilization === 'none')}
          {determineNotScopedIdentifier() && <div>({ALERT_ICON})</div>}
        </span>
      </div>
    );
  };

  const setColorIcon = (): JSX.Element => (
    <div className="relative flex flex-row items-center">
      {isSetColorOpened && (
        <div className="absolute right-full z-0 mr-4 h-full">
          <div className="flex items-center justify-center">
            {Object.entries(itemRowcolors).map(([key, value]) => (
              <div key={key} className="px-1">
                <button
                  type="button"
                  aria-label="Set color"
                  className={`w-5 h-5 inline-flex rounded-full cursor-pointer focus:outline-none focus:shadow-outline ${value}`}
                  onClick={(): void => {
                    setIsSetColorOpened(false);
                    modifyColor(value);
                  }}
                />
              </div>
            ))}
          </div>
        </div>
      )}
      <button
        type="button"
        aria-label="Open color picker"
        className={`w-5 h-5 rounded-full focus:outline-none inline-flex ${
          data.color || 'bg-litlingo-gray-1'
        }`}
        onClick={(): void => setIsSetColorOpened(!isSetColorOpened)}
      />
    </div>
  );

  const renderModifiedIcon = (modifiedId: string): JSX.Element | null => {
    if (modifiedNodes && modifiedNodes.indexOf(modifiedId) !== -1) return modifiedIcon;
    if (addedNodes && addedNodes.indexOf(modifiedId) !== -1) return modifiedIcon;
    return null;
  };

  const handleInputFocus = (
    e: React.MouseEvent<HTMLInputElement | HTMLButtonElement, MouseEvent>
  ): void => {
    e.preventDefault();
    setInputFocused(true);
  };

  if (!rule) return null;

  const renderLastModified = (): JSX.Element | null => {
    const getModifiedTextColor = (): string => {
      if (disabled && utilization === 'none') return 'text-litlingo-gray-4';
      if (disabled) return 'text-litlingo-gray-3';
      return 'text-litlingo-gray-6';
    };

    if (!hover) return null;

    return (
      <div className={`flex flex-row gap-1 ml-4 ${getModifiedTextColor()} `}>
        <span>{rule.updated_by?.name}</span>
        <span>({moment(rule.updated_at).fromNow()})</span>
      </div>
    );
  };

  const renderDescription = (): JSX.Element => {
    if (data.description || inputFocused) {
      return (
        <div className="flex flex-row  items-center">
          <span style={disabled ? { filter: 'grayscale(1)' } : {}}>
            {NOTES_ICON_REDESIGN(disabled, utilization === 'none')}
          </span>
          <input
            className={`italic ml-1 mr-2 text-sx pl-1 pt-0.5  border border-transparent border-solid box-border bg-transparent transition duration-150 ease-in-out sm:text-sm sm:leading-5  focus:border focus:border-litlingo-line focus:border-solid overflow-y-hidden truncate ${
              disabled ? 'text-litlingo-gray-3' : 'text-litlingo-gray-4'
            } `}
            placeholder="Add a description..."
            data-testid="description-input"
            value={description || ''}
            onChange={(e): void => modifyDescription(e.target.value)}
            onClick={(e): void => handleInputFocus(e)}
            onBlur={(): void => setInputFocused(false)}
            style={{
              minWidth: '120px',
              width,
            }}
            ref={span}
            disabled={compareMode || shouldDisable}
          />
        </div>
      );
    }
    return (
      <>
        {hover && (
          <button
            style={{ filter: 'grayscale(1)' }}
            type="button"
            onClick={(e): void => handleInputFocus(e)}
            className="mr-2.5 focus:outline-none"
            disabled={compareMode || shouldDisable}
          >
            {NOTES_ICON_REDESIGN(disabled, utilization === 'none')}
          </button>
        )}
      </>
    );
  };

  const handleLinkClick = async (): Promise<void> => {
    const link = new URL(window.location.href);

    link.searchParams.delete('highlighted_rule');
    link.searchParams.append('highlighted_rule', id);

    await navigator.clipboard.writeText(link.href);
    dispatch(showSuccessAlert('Link copied to your clipboard'));
  };

  return (
    <div>
      <DragPreviewImage connect={preview} src={image} />
      <Tooltip tooltip={disabled ? 'ruleConfig.disabledBranch' : ''}>
        <div
          ref={ref}
          id={id}
          data-testid={`rule-manager-table-tr-${id}`}
          onMouseEnter={(): void => setHover(true)}
          onMouseLeave={(): void => {
            setHover(false);
            setInputFocused(false);
          }}
          className={`table-wrapper__logic-builder-tr flex flex-row gap-2 items-center justify-between w-full relative px-6 ${
            !hover ? 'h-7' : ''
          } 
          ${getBackgroundColor()}
          ${disabled ? 'text-litlingo-gray-3' : 'text-litlingo-gray-6'}
          ${lastChangedNode === id && showTransition ? 'bg-gray-transition' : ''}
          ${collapsedItems.includes(id) ? 'hidden' : ''}
          ${index === dropPosition && 'border-b-2 border-litlingo-success'}
        `}
        >
          {selectedCustomer && scopedCustomers.includes(selectedCustomer) && !compareMode && (
            <div className="absolute top-0 bottom-0 left-0 right-0 bg-litlingo-green-bg bg-opacity-50" />
          )}

          {showHeatmap && (
            <div className={`absolute top-0 bottom-0 left-0 right-0 ${heatColor} bg-opacity-50`} />
          )}

          {determineNotScopedIdentifier() && (
            <div className={`absolute top-0 bottom-0 left-0 right-0 `} />
          )}

          <div
            className={`${
              actionItem === null ? 'text-gray-300' : ''
            } relative whitespace-no-wrap text-body text-left flex flex-row items-center p-1  w-full`}
          >
            <div className="flex flex-row">
              <div
                className={`${
                  disabled ? 'text-litlingo-gray-3' : 'text-litlingo-gray-6'
                } text-right mr-2 ${node.typeOfConfig === 'ANNOTATION_MATCH' && node.annotatorId}`}
                style={{ width: '24px' }}
              >
                {index}
              </div>
              <div>{actionItem}</div>
            </div>
            {indentation.repeat(level)}

            {renderName(id)}
            <div className="flex flex-row w-full items-center" ref={containerP}>
              {operatorName[name as keyof typeof operatorName] && (
                <div
                  className="ml-4"
                  style={{
                    maxWidth: 'calc(55vw - 356px)',
                  }}
                >
                  {renderDescription()}
                </div>
              )}
              <span className="border-b w-full border-litlingo-gray-1" ref={container} />
              {renderLastModified()}
            </div>
          </div>

          <div
            className={`relative flex flex-row space-x-1 ${
              !!modifiedNodes || !!addedNodes ? 'mr-1' : null
            }`}
          >
            {operatorName[name as keyof typeof operatorName] && (
              <div className="flex flex-row">{setColorIcon()}</div>
            )}
            <div className="flex flex-row ">{renderModifiedIcon(id)}</div>
            {selectedNode === index && (
              <button type="button" className="focus:outline-none" onClick={handleLinkClick}>
                {LINK_ICON}
              </button>
            )}
            {['RELATIONSHIP_MATCH', 'OR', 'AND'].includes(data.name) ? (
              <div className="flex justify-end text-body">
                <span className="font-bold">{hits}</span>
              </div>
            ) : (
              <div className="flex justify-end text-body">
                <span className="font-bold">-</span>
              </div>
            )}
          </div>
        </div>
      </Tooltip>
      {renderRelationship()}
    </div>
  );
};

export default RowItem;

import type { PayloadAction } from '@reduxjs/toolkit';
import { createReducer } from '@reduxjs/toolkit';
import {
  createModelFailure,
  createModelFulfill,
  createModelRequest,
  createModelSuccess,
  deleteModelCategorySuccess,
  deleteModelFailure,
  deleteModelFulfill,
  deleteModelRequest,
  deleteModelSuccess,
  fetchAllModelsFailure,
  fetchAllModelsFulfill,
  fetchAllModelsRequest,
  fetchAllModelsSuccess,
  FetchAllModelsSuccessPayload,
  fetchModelCategoriesFailure,
  fetchModelCategoriesFulfill,
  fetchModelCategoriesRequest,
  fetchModelCategoriesSuccess,
  fetchSingleModelFailure,
  fetchSingleModelFulfill,
  fetchSingleModelRequest,
  fetchSingleModelSuccess,
  promoteAllFailure,
  promoteAllRequest,
  promoteAllSuccess,
  upsertModelCategoryFailure,
  upsertModelCategoryFulfill,
  upsertModelCategoryRequest,
  upsertModelCategorySuccess,
  upsertModelFailure,
  upsertModelFulfill,
  upsertModelRequest,
  upsertModelSuccess,
} from 'actions/models';

import type { Category, ErrorObject, MModel, Model, NormalizedResource, UUID } from 'types';

export type ModelState = {
  models: NormalizedResource<MModel>;
  filterItems: NormalizedResource<MModel>;
  activeModel: UUID;
  error: ErrorObject | null;
  loading: string[];
  count: number;
  activeIntegrations: string[];
};
type ModelReducer<P = void> = (state: ModelState, action: PayloadAction<P>) => ModelState;

const defaultState: ModelState = {
  models: {},
  activeModel: '',
  filterItems: {},
  error: null,
  loading: [],
  count: 0,
  activeIntegrations: [],
};

const handleFetchAllModelsRequest: ModelReducer = (state) => ({
  ...state,
  error: null,
  loading: [...state.loading, fetchAllModelsRequest.toString()],
});

const handleFetchAllModelsSuccess: ModelReducer<FetchAllModelsSuccessPayload> = (
  state,
  { payload }
) => {
  const models: NormalizedResource<Model> = {};
  payload.records.forEach((newModel) => {
    const { uuid } = newModel;
    const model = state.models[uuid] || {};

    models[uuid] = {
      ...model,
      ...newModel,
    };
  });

  return {
    ...state,
    models: {
      ...models,
    },
    count: payload.count,
  };
};

const handleFetchAllModelsFailure: ModelReducer<ErrorObject> = (state, { payload }) => ({
  ...state,
  error: payload,
});

const handleFetchAllModelsFulfill: ModelReducer = (state) => ({
  ...state,
  loading: state.loading.filter((s) => s !== fetchAllModelsRequest.toString()),
});

const handleFetchSingleModelRequest: ModelReducer = (state) => ({
  ...state,
  error: null,
  loading: [...state.loading, fetchSingleModelRequest.toString()],
});

const handleFetchSingleModelSuccess: ModelReducer<Model> = (state, { payload }) => {
  const item = payload;
  const model = state.models[item.uuid] || {};

  return {
    ...state,
    models: {
      ...state.models,
      [payload.uuid]: { ...model, ...item },
    },
  };
};

const handleFetchSingleModelFailure: ModelReducer<ErrorObject> = (state, { payload }) => ({
  ...state,
  error: payload,
});

const handleFetchSingleModelFulfill: ModelReducer = (state) => ({
  ...state,
  loading: state.loading.filter((s) => s !== fetchSingleModelRequest.toString()),
});

const handleCreateModelRequest: ModelReducer = (state) => ({
  ...state,
  error: null,
  loading: [...state.loading, createModelRequest.toString()],
});

const handleCreateModelSuccess: ModelReducer<Model> = (state, { payload }) => ({
  ...state,
  models: {
    ...state.models,
    [payload.uuid]: { ...payload, isNew: true },
  },
});

const handleCreateModelFailure: ModelReducer<ErrorObject> = (state, { payload }) => ({
  ...state,
  error: payload,
});

const handleCreateModelFulfill: ModelReducer = (state) => ({
  ...state,
  loading: state.loading.filter((s) => s !== createModelRequest.toString()),
});

const handleDeleteModelRequest: ModelReducer<string> = (state, { payload: id }) => ({
  ...state,
  models: {
    ...state.models,
    [id]: { ...state.models[id], unSaved: true },
  },
  error: null,
  loading: [...state.loading, deleteModelRequest.toString()],
});

const handleDeleteModelSuccess: ModelReducer<string> = (state, { payload: id }) => {
  const models = { ...state.models };
  delete models[id];

  return {
    ...state,
    models,
  };
};

const handleDeleteModelFailure: ModelReducer<ErrorObject> = (state, { payload }) => ({
  ...state,
  error: payload,
});

const handleDeleteModelFulfill: ModelReducer = (state) => ({
  ...state,
  loading: state.loading.filter((s) => s !== deleteModelRequest.toString()),
});

const handleUpsertModelRequest: ModelReducer<MModel> = (state) => ({
  ...state,

  loading: [...state.loading, upsertModelRequest.toString()],
});

const handleUpsertModelSuccess: ModelReducer<Model> = (state, { payload }) => ({
  ...state,
  models: {
    ...state.models,
    [payload.uuid]: {
      ...state.models[payload.uuid],
      ...payload,
    },
  },
});

const handleUpsertModelFailure: ModelReducer<ErrorObject> = (state, { payload }) => ({
  ...state,
  error: payload,
});

const handleUpsertModelFulfill: ModelReducer = (state) => ({
  ...state,
  loading: state.loading.filter((s) => s !== upsertModelRequest.toString()),
});

const handleFetchModelCategoriesRequest: ModelReducer<{ modelId: UUID }> = (
  state,
  { payload }
) => ({
  ...state,
  loading: [...state.loading, `${fetchModelCategoriesRequest.toString()}-${payload.modelId}`],
});

const handleFetchModelCategoriesSuccess: ModelReducer<
  ReturnType<typeof fetchModelCategoriesSuccess>['payload']
> = (state, { payload }) => ({
  ...state,
  models: {
    ...state.models,
    [payload.modelId]: {
      ...state.models[payload.modelId],
      categories: payload.categories,
    },
  },
});

const handleFetchModelCategoriesFailure: ModelReducer<ErrorObject> = (state, { payload }) => ({
  ...state,
  error: payload,
});

const handleFetchModelCategoriesFulfill: ModelReducer<{ modelId: UUID }> = (
  state,
  { payload }
) => ({
  ...state,
  loading: state.loading.filter(
    (s) => s !== `${fetchModelCategoriesRequest.toString()}-${payload.modelId}`
  ),
});

const handleUpsertModelCategoryRequest: ModelReducer = (state) => ({
  ...state,
  loading: [...state.loading, upsertModelCategoryRequest.toString()],
});

const handleUpsertModelCategorySuccess: ModelReducer<Category> = (state, { payload }) => {
  const model = { ...state.models[payload.model_uuid] };

  model.categories = [...(model.categories || []).filter((c) => c.uuid !== payload.uuid), payload];

  return {
    ...state,
    models: { ...state.models, [payload.model_uuid]: model },
  };
};

const handleUpsertModelCategoryFailure: ModelReducer<ErrorObject> = (state, { payload }) => ({
  ...state,
  error: payload,
});

const handleUpsertModelCategoryFulfill: ModelReducer = (state) => ({
  ...state,
  loading: state.loading.filter((s) => s !== upsertModelCategoryRequest.toString()),
});

const handleDeleteModelCategorySuccess: ModelReducer<
  ReturnType<typeof deleteModelCategorySuccess>['payload']
> = (state, { payload }) => {
  const { id, categoryId } = payload;
  const model = { ...state.models[id] };
  if (model && model.categories) {
    model.categories = model.categories.filter((c) => c.uuid !== categoryId);
  }
  return {
    ...state,
    models: { ...state.models, [id]: model },
  };
};

const handlePromoteAllRequest: ModelReducer = (state) => ({
  ...state,
  loading: [...state.loading, promoteAllRequest.toString()],
});
const handlePromoteAllFailure: ModelReducer<ErrorObject> = (state, { payload }) => ({
  ...state,
  error: payload,
  loading: state.loading.filter((s) => s !== promoteAllRequest.toString()),
});
const handlePromoteAllSuccess: ModelReducer = (state) => ({
  ...state,
  loading: state.loading.filter((s) => s !== promoteAllRequest.toString()),
});

const handlers = {
  [fetchAllModelsRequest.toString()]: handleFetchAllModelsRequest,
  [fetchAllModelsSuccess.toString()]: handleFetchAllModelsSuccess,
  [fetchAllModelsFailure.toString()]: handleFetchAllModelsFailure,
  [fetchAllModelsFulfill.toString()]: handleFetchAllModelsFulfill,

  [fetchSingleModelRequest.toString()]: handleFetchSingleModelRequest,
  [fetchSingleModelSuccess.toString()]: handleFetchSingleModelSuccess,
  [fetchSingleModelFailure.toString()]: handleFetchSingleModelFailure,
  [fetchSingleModelFulfill.toString()]: handleFetchSingleModelFulfill,

  [createModelRequest.toString()]: handleCreateModelRequest,
  [createModelSuccess.toString()]: handleCreateModelSuccess,
  [createModelFailure.toString()]: handleCreateModelFailure,
  [createModelFulfill.toString()]: handleCreateModelFulfill,

  [deleteModelRequest.toString()]: handleDeleteModelRequest,
  [deleteModelSuccess.toString()]: handleDeleteModelSuccess,
  [deleteModelFailure.toString()]: handleDeleteModelFailure,
  [deleteModelFulfill.toString()]: handleDeleteModelFulfill,

  [upsertModelRequest.toString()]: handleUpsertModelRequest,
  [upsertModelSuccess.toString()]: handleUpsertModelSuccess,
  [upsertModelFailure.toString()]: handleUpsertModelFailure,
  [upsertModelFulfill.toString()]: handleUpsertModelFulfill,

  [fetchModelCategoriesRequest.toString()]: handleFetchModelCategoriesRequest,
  [fetchModelCategoriesSuccess.toString()]: handleFetchModelCategoriesSuccess,
  [fetchModelCategoriesFailure.toString()]: handleFetchModelCategoriesFailure,
  [fetchModelCategoriesFulfill.toString()]: handleFetchModelCategoriesFulfill,

  [upsertModelCategoryRequest.toString()]: handleUpsertModelCategoryRequest,
  [upsertModelCategorySuccess.toString()]: handleUpsertModelCategorySuccess,
  [upsertModelCategoryFailure.toString()]: handleUpsertModelCategoryFailure,
  [upsertModelCategoryFulfill.toString()]: handleUpsertModelCategoryFulfill,

  [promoteAllRequest.toString()]: handlePromoteAllRequest,
  [promoteAllFailure.toString()]: handlePromoteAllFailure,
  [promoteAllSuccess.toString()]: handlePromoteAllSuccess,

  [deleteModelCategorySuccess.toString()]: handleDeleteModelCategorySuccess,
};

const modelsReducer = createReducer(defaultState, handlers);

export default modelsReducer;

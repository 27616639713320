import { promoteAll } from 'actions';
import CategoriesByRuleTable from 'components/CategoriesTable';
import CreateCategoryModal from 'components/Category/CreateCategoryModal';
import CollapsableCard from 'components/CollapsableCard';
import Modal from 'components/Modal';
import Permissions from 'components/Permissions';
import { MODAL_BACKGROUND } from 'constants/common';
import React, { useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getFetchModelCategoriesLoading, getModelCategories } from 'selectors/models';
import { useSelector } from 'store';
import type { MModel, UUID } from 'types';

type RouteParams = {
  modelId: UUID;
};

type ComponentProps = {
  item: MModel;
};

const RenderCategories: React.FC<ComponentProps> = ({ item }) => {
  const { modelId } = useParams<RouteParams>();
  const dispatch = useDispatch();

  const categories = useSelector((state) => getModelCategories(state, modelId));
  const loading = useSelector(getFetchModelCategoriesLoading(modelId));

  const [isCreatingCategory, setIsCreatingCategory] = useState(false);
  const [showPromoteModal, setShowPromoteModal] = useState(false);

  const toggleCreateCategoryModal = (): void => {
    setIsCreatingCategory(!isCreatingCategory);
  };

  const togglePromoteModal = (): void => {
    setShowPromoteModal((b) => !b);
  };

  const handlePromoteRules = (): void => {
    dispatch(promoteAll({ modelId }));
    setShowPromoteModal(false);
  };

  const promoteAllButton = (
    <Permissions action="rules.create">
      <span className="rounded-md">
        <button
          onClick={(e): void => {
            e.stopPropagation();
            togglePromoteModal();
          }}
          type="button"
          data-testid="create-rule-form"
          className="button button--secondary button--green-text font-bold h-8"
          /* @ts-ignore */
          disabled={categories.every((c) => c.tenant_name === 'human')}
        >
          Accept All Changes
        </button>
      </span>
    </Permissions>
  );

  const createCategoryButton = (
    <Permissions action="rules.create">
      <span className="rounded-md">
        <button
          onClick={(e): void => {
            e.stopPropagation();
            setIsCreatingCategory(true);
          }}
          type="button"
          data-testid="create-rule-form"
          className="button button--secondary button--green-text font-bold h-8"
        >
          Create Model
        </button>
      </span>
    </Permissions>
  );

  const renderActions = (
    <div className="flex flex-row gap-4">
      {promoteAllButton}
      {createCategoryButton}
    </div>
  );

  const skeletonLoading = (): JSX.Element => (
    <div className="px-12 py-5">
      <Skeleton count={5} />
    </div>
  );

  const renderContent = (): JSX.Element => {
    if (categories?.length === 0 && !loading) {
      return (
        <div className="flex flex-col justify-center items-center gap-4 py-8">
          <span className="text-body">
            Use cases make up the conditions the model will monitor and match against
          </span>
          <button
            onClick={(e): void => {
              e.stopPropagation();
              setIsCreatingCategory(true);
            }}
            type="button"
            data-testid="create-rule-form"
            className="button button--primary h-8"
          >
            Create Model
          </button>
        </div>
      );
    }

    return (
      <div className="border rounded">
        {categories.length === 0 && loading ? (
          skeletonLoading()
        ) : (
          <CategoriesByRuleTable categories={categories} />
        )}
      </div>
    );
  };

  const renderBody = (): JSX.Element => (
    <>
      <div className="absolute top-5 left-0 right-0 h-40 z-0">{MODAL_BACKGROUND}</div>

      <div className="flex flex-col mt-30 mb-8 gap-2">
        <h2 className="text-heading-1">Accept All Changes</h2>
        <p className="text-body">
          Are you sure you want to accept all changes to the rules in this model?
        </p>
      </div>
    </>
  );

  return (
    <>
      <CollapsableCard headerTitle="Models" customAction={renderActions}>
        <div className="p-4">{renderContent()}</div>
      </CollapsableCard>
      {isCreatingCategory && (
        <CreateCategoryModal
          modelId={item.uuid}
          toggleModal={toggleCreateCategoryModal}
          redirect="global-rule-group-manager"
        />
      )}
      {showPromoteModal && (
        <Modal
          title=" "
          body={renderBody()}
          okButton
          okButtonText="Accept Changes"
          okButtonOnClick={handlePromoteRules}
          okButtonStyle="whitespace-no-wrap w-full px-4 mr-4"
          cancelButtonText="Cancel"
          cancelButtonOnclick={(): void => setShowPromoteModal(false)}
          toggleShowModal={togglePromoteModal}
        />
      )}
    </>
  );
};

export default RenderCategories;

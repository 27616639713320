/* eslint-disable max-lines */

import {
  clearURLParams,
  extractBulk,
  extractRecipientDomains,
  extractSenderDomains,
  fetchActiveIntegrations,
  fetchAnnotatorsForFilter,
  fetchCustomerRecipientsDomain,
  fetchCustomerUsersDomain,
  fetchReviewers,
  fetchTagGroups,
  fetchTagsForFilter,
  fetchTeamsForFilter,
  fetchUsersForFilter,
  fetchUsersForFilterAllUsers,
  reprocessBulk,
  setTree,
} from 'actions';
import { orderByNewParam } from 'actions/envelopeListView';
import { fetchRuleContext } from 'actions/ruleGroup';
import EnvelopeListSidebarCollapsableSection from 'components/CollapsableSection/EnvelopeListSidebarCollapsableSection';
import CreateReviewSetHowTo from 'components/EnvelopeList/CreateReviewSetHowTo';
import ResourceFilter from 'components/Filters/ResourceFilter';
import DateFilter from 'components/Filters/SidebarTypeFilters/DateFilter';
import Permissions from 'components/Permissions';
import ThreeDotsMenu from 'components/ThreeDotsMenu';
import { GRAY_INFO_ICON } from 'constants/dashboardIcons';
import { resourceQueryParamName } from 'constants/resourceQueryNames';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  getAnnotators,
  getAnnotatorsTotalCount,
  getFetchAllAnnotatorsLoading,
} from 'selectors/annotator';
import { getLowDataMode, getSurroundingContextRulesIds, getUser } from 'selectors/auth';
import { getActiveIntegrationsLoading } from 'selectors/campaigns';
import { eventsSidebarState } from 'selectors/envelopeListView';
import { getKeyActionsNames, getPlatformCount, getPlatformNames } from 'selectors/events';
import { getNavParamsByResourceMemo, getNavParamsFromTree } from 'selectors/nav';
import { getReviewLabelOptionsForDashboard } from 'selectors/reviewStatus';
import { getRuleRevisions } from 'selectors/ruleGroups';
import { getTagsTotalCount } from 'selectors/tags';
import { getTeams, getTeamsCount, getTeamsLoading } from 'selectors/teams';
import {
  getCustomerRecipientsDomain,
  getCustomerRecipientsDomainCount,
  getCustomerRecipientsDomainLoading,
  getCustomerSenderDomain,
  getCustomerSenderDomainCount,
  getCustomerSenderDomainLoading,
  getUsersLoading,
  getUsersNoSuperAdminList,
  getUsersTotalCount,
} from 'selectors/users';
import { useSelector } from 'store';
import logEvent from 'utils/analytics';
import { Operator } from 'utils/parserTree';
import { v4 } from 'uuid';
import AdvancedSearchModal from './AdvancedSearchModal';
import BackendResourceFilter from './BackendResourceFilter';
import BackendResourceFilterContext from './BackendResourceFilter/BackendResourceFilterContext';
import DaysOutOfRetentionFilter from './DaysOutOfRetention';
import CategoriesFilter from './SidebarTypeFilters/CategoriesFilter/CategoriesFilter';
import MultiSelectFilter from './SidebarTypeFilters/MultiSelectFilter';
import TagsFilter from './SidebarTypeFilters/TagsFilter/TagsFilter';
import SortFilter from './SortFilter';

type ComponentProps = {
  resource: string;
};

const EventsFiltersSidebar: React.FC<ComponentProps> = ({ resource }) => {
  const dispatch = useDispatch();

  const { furtherReviewTagGroup, furtherReviewTagGroupId } = useSelector(eventsSidebarState);
  const [forceInfoModalOpen, setForceInfoModalOpen] = useState(0);
  const [isAdvancedSearchModalOpen, setIsAdvancedSearchModalOpen] = useState(false);

  const params = useSelector((state) => getNavParamsByResourceMemo(state, resource));
  const treeParams = useSelector(getNavParamsFromTree);

  const contextRules = useSelector(getSurroundingContextRulesIds);
  const lowDataMode = useSelector(getLowDataMode);

  const user = useSelector(getUser);
  const filteredParams = Object.entries(params).filter(
    ([key]) => !['offset', 'limit', 'order_desc', 'order_by', 'has_events'].includes(key)
  );

  useEffect(() => {
    if (furtherReviewTagGroupId) {
      dispatch(
        fetchTagGroups({
          uuids: [furtherReviewTagGroupId],
        })
      );
    }
  }, [dispatch, furtherReviewTagGroupId]);

  const handleResetFiltersClick = (): void => {
    logEvent('envelopes-list-clear-filters');
    dispatch(setTree({ tree: { op: Operator.AND, data: [], id: v4() } }));
    dispatch(clearURLParams());
    dispatch(orderByNewParam(false));
  };

  const handleReprocessStarshipClick = (): void => {
    dispatch(reprocessBulk({ starship: true }));
  };

  const handleExtractClick = (): void => {
    dispatch(extractBulk({ starship: true }));
  };

  const handleExtractSenderDomains = (): void => {
    dispatch(extractSenderDomains);
  };

  const handleExtractRecipientDomains = (): void => {
    dispatch(extractRecipientDomains);
  };

  const toggleAdvancedSearchModalOpen = (): void => {
    setIsAdvancedSearchModalOpen(!isAdvancedSearchModalOpen);
  };

  const handleOpenInfo = (): void => {
    setForceInfoModalOpen((i) => i + 1);
  };

  const options = [
    {
      id: 'advanced-query',
      label: 'Advanced Query',
      action: toggleAdvancedSearchModalOpen,
      permissions: 'communications.advanced',
    },
    {
      id: 'reprocess-starship',
      label: 'Reprocess Starship',
      action: handleReprocessStarshipClick,
      permissions: 'communications.reprocess',
    },
    {
      id: 'Extract-entites',
      label: 'Extract Entities',
      action: handleExtractClick,
      // TODO: more perms
      permissions: 'communications.reprocess',
    },
    {
      id: 'Extract-sender-domains',
      label: 'Sender Domains',
      action: handleExtractSenderDomains,
      permissions: 'communications.reprocess',
    },
    {
      id: 'Extract-recipient-domains',
      label: 'Recipient Domains',
      action: handleExtractRecipientDomains,
      permissions: 'communications.reprocess',
    },
  ];

  return (
    <div className="flex flex-col gap-4">
      <div id="envelope-header-div" className="bg-litlingo-primary-140">
        <div className="items-center flex flex-row justify-between">
          <div className="flex flex-row justify-start gap-2">
            <div className="flex flex-row items-baseline text-heading-2">
              <span className="text-white">Filters</span>
            </div>
            <Permissions action="communications.reprocess">
              <div className="flex flex-col justify-center items-end">
                <ThreeDotsMenu options={options} colorClass="text-white" translate={false} />
              </div>
            </Permissions>
          </div>
          <button
            type="button"
            className={`underline focus:outline-none  ${
              !(filteredParams.length > 0) && 'text-litlingo-gray-4 cursor-default'
            }`}
            onClick={handleResetFiltersClick}
            disabled={!(filteredParams.length > 0)}
          >
            Clear All
          </button>
        </div>
      </div>
      <div className="flex flex-col relative z-20">
        <div>
          <EnvelopeListSidebarCollapsableSection
            headerTitle="Date"
            isDefaultClosed
            selectedFilters={
              (params && (params.created_before || params.created_after)) ||
              (treeParams && treeParams.date_range)
            }
          >
            <div className="flex flex-col gap-2">
              <DateFilter resource={resource} />
              <SortFilter resource={resourceQueryParamName.envelopes} />
            </div>
          </EnvelopeListSidebarCollapsableSection>

          <EnvelopeListSidebarCollapsableSection
            headerTitle="Models"
            isDefaultClosed
            selectedFilters={
              treeParams.campaign_uuids ||
              treeParams.campaign_uuids_and ||
              treeParams.not_campaign_uuids ||
              treeParams.not_campaign_uuids_and ||
              treeParams.rule_uuids ||
              treeParams.rule_uuids_and ||
              treeParams.not_rule_uuids ||
              treeParams.not_rule_uuids_and ||
              treeParams.body_campaign_uuids ||
              treeParams.not_body_campaign_uuids ||
              treeParams.body_rule_uuids ||
              treeParams.not_body_rule_uuids ||
              treeParams.attachment_campaign_uuids ||
              treeParams.not_attachment_campaign_uuids ||
              treeParams.attachment_rule_uuids ||
              treeParams.not_attachment_rule_uuids ||
              treeParams.annotator_uuids ||
              treeParams.not_annotator_uuids
            }
          >
            <div className="flex flex-col gap-y-3 mt-2">
              <Permissions action="communication_envelopes.filter_categories">
                <div>
                  <div>Models</div>
                  <CategoriesFilter
                    campaignsKey="campaign_uuids"
                    rulesKey="rule_uuids"
                    title="Models"
                  />
                </div>
              </Permissions>
              <Permissions action="communication_envelopes.list">
                <div>
                  <div>Models in Body</div>
                  <CategoriesFilter
                    campaignsKey="body_campaign_uuids"
                    rulesKey="body_rule_uuids"
                    title="Models"
                  />
                </div>
              </Permissions>
              <Permissions action="communication_envelopes.list">
                <div>
                  <div>Models in Attachment</div>
                  <CategoriesFilter
                    campaignsKey="attachment_campaign_uuids"
                    rulesKey="attachment_rule_uuids"
                    title="Models"
                  />
                </div>
              </Permissions>
              <Permissions action="communication_envelopes.filter_annotators">
                <div>
                  <div>Identifiers</div>
                  <BackendResourceFilter
                    resource={resource}
                    fetchAction={fetchAnnotatorsForFilter}
                    getResourceList={getAnnotators}
                    getResourceCount={getAnnotatorsTotalCount}
                    getLoading={getFetchAllAnnotatorsLoading}
                    filterKey="annotator_uuids"
                    title="Identifiers"
                    nameKey="name"
                    dataTestid="identifiers-filter-button"
                    allowExclude
                  />
                </div>
              </Permissions>
            </div>
          </EnvelopeListSidebarCollapsableSection>

          <EnvelopeListSidebarCollapsableSection
            headerTitle="Message Properties"
            isDefaultClosed
            selectedFilters={
              treeParams.recipients ||
              treeParams.not_recipients ||
              treeParams.sender_team_uuids_and ||
              treeParams.sender_team_uuids ||
              treeParams.not_sender_team_uuids ||
              treeParams.platforms ||
              treeParams.not_platforms ||
              treeParams.has_attachments ||
              treeParams.inbound ||
              treeParams.has_translation ||
              treeParams.created_by ||
              treeParams.not_created_by ||
              treeParams.sender_domain ||
              treeParams.not_sender_domain ||
              treeParams.recipient_domains ||
              treeParams.not_recipient_domains ||
              treeParams.rule_context_uuids ||
              treeParams.rule_context_uuids_and ||
              treeParams.not_rule_context_uuids ||
              treeParams.not_rule_context_uuids_and
            }
          >
            <div className="flex flex-col gap-y-3 mt-2">
              {contextRules.length > 0 && (
                <Permissions action="users.list">
                  <div>
                    <div>Context</div>
                    <BackendResourceFilterContext
                      resource={resource}
                      fetchAction={fetchRuleContext}
                      getResourceList={getRuleRevisions}
                      uuids={contextRules}
                      getLoading={getUsersLoading}
                      filterKey="rule_context_uuids"
                      title="Context"
                      nameKey="name"
                      dataTestid="context-filter-button"
                      allowExclude
                    />
                  </div>
                </Permissions>
              )}
              {!lowDataMode && (
                <Permissions action="users.list">
                  <div>
                    <div>Sender</div>
                    <BackendResourceFilter
                      resource={resource}
                      fetchAction={fetchUsersForFilter}
                      getResourceList={getUsersNoSuperAdminList}
                      getResourceCount={getUsersTotalCount}
                      getLoading={getUsersLoading}
                      filterKey="created_by"
                      title="Sender"
                      nameKey="name"
                      secondNameKey="email"
                      dataTestid="user-filter-button"
                      allowExclude
                    />
                  </div>
                </Permissions>
              )}
              {!lowDataMode && (
                <Permissions action="users.list">
                  <div>
                    <div>Recipient</div>
                    <BackendResourceFilter
                      resource={resource}
                      fetchAction={fetchUsersForFilterAllUsers}
                      getResourceList={getUsersNoSuperAdminList}
                      getResourceCount={getUsersTotalCount}
                      getLoading={getUsersLoading}
                      filterKey="recipients"
                      title="Recipients"
                      nameKey="name"
                      itemField="email"
                      secondNameKey="email"
                      dataTestid="user-filter-button"
                      allowExclude
                    />
                  </div>
                </Permissions>
              )}
              <Permissions action="users.domains">
                <div>
                  <div>Sender Domains</div>
                  <BackendResourceFilter
                    resource={resource}
                    fetchAction={fetchCustomerUsersDomain}
                    getResourceList={getCustomerSenderDomain}
                    getResourceCount={getCustomerSenderDomainCount}
                    getLoading={getCustomerSenderDomainLoading}
                    filterKey="sender_domain"
                    title="Sender Domains"
                    nameKey="name"
                    dataTestid="user-filter-button"
                    allowExclude
                    notTreeLabel
                  />
                </div>
              </Permissions>
              <Permissions action="users.domains">
                <div>
                  <div>Recipients Domains</div>
                  <BackendResourceFilter
                    resource={resource}
                    fetchAction={fetchCustomerRecipientsDomain}
                    getResourceList={getCustomerRecipientsDomain}
                    getResourceCount={getCustomerRecipientsDomainCount}
                    getLoading={getCustomerRecipientsDomainLoading}
                    filterKey="recipient_domains"
                    title="Recipient Domains"
                    nameKey="name"
                    dataTestid="user-filter-button"
                    allowExclude
                    notTreeLabel
                  />
                </div>
              </Permissions>
              <Permissions action="communication_envelopes.filter_division">
                <div>
                  <div>Division</div>
                  <BackendResourceFilter
                    resource={resource}
                    fetchAction={fetchTeamsForFilter}
                    getResourceCount={getTeamsCount}
                    getResourceList={getTeams}
                    getLoading={getTeamsLoading}
                    filterKey="sender_team_uuids"
                    title="Division"
                    nameKey="name"
                    allowExclude
                  />
                </div>
              </Permissions>
              <Permissions action="communication_envelopes.filter_direction">
                <div>
                  <div>Direction</div>
                  <MultiSelectFilter
                    options={{
                      true: 'Inbound',
                      false: 'Outbound',
                    }}
                    filter="inbound"
                  />
                </div>
              </Permissions>
              <Permissions action="communication_envelopes.filter_source">
                <div>
                  <div>Platform</div>
                  <BackendResourceFilter
                    resource={resource}
                    getResourceList={getPlatformNames}
                    fetchAction={fetchActiveIntegrations}
                    getResourceCount={getPlatformCount}
                    getLoading={getActiveIntegrationsLoading}
                    filterKey="platforms"
                    title="Platform"
                    nameKey="name"
                    dataTestid="platform-filter-button"
                    allowExclude
                    notTreeLabel
                  />
                </div>
              </Permissions>
              <div>
                <div>Attachments</div>
                <MultiSelectFilter
                  options={{
                    true: 'Has Attachments',
                    false: 'No Attachments',
                  }}
                  filter="has_attachments"
                />
              </div>
              <Permissions action="communication_envelopes.filter_same_sentence">
                <div>
                  <div>Translations</div>
                  <MultiSelectFilter
                    options={{
                      true: 'Has Translations',
                      false: 'No Translations',
                    }}
                    filter="has_translation"
                  />
                </div>
              </Permissions>
              {user.customer?.config.communication_retention_days && (
                <div>
                  <div>Retention Window</div>

                  <DaysOutOfRetentionFilter resource={resource} />
                </div>
              )}
            </div>
          </EnvelopeListSidebarCollapsableSection>

          <EnvelopeListSidebarCollapsableSection
            headerTitle="Reviewer Actions"
            isDefaultClosed
            selectedFilters={
              treeParams.tags ||
              treeParams.not_tags ||
              treeParams.tag_value_groups ||
              treeParams.not_tag_value_groups ||
              treeParams.review_values ||
              treeParams.not_review_values ||
              treeParams.review_values ||
              treeParams.not_review_values ||
              treeParams.reviewed_by_user_uuids ||
              treeParams.not_reviewed_by_user_uuids ||
              treeParams.has_comments ||
              furtherReviewTagGroup?.tag_values
                .filter((t) => params.tags?.includes(t.uuid))
                .map((tag) => tag.uuid)
            }
          >
            <div className="flex flex-col gap-y-3 mt-2">
              <Permissions action="communication_envelopes.filter_status">
                <div>
                  <div>Status</div>
                  <ResourceFilter
                    getResourceList={getReviewLabelOptionsForDashboard}
                    filterKey="review_values"
                    title="Status"
                    nameKey="name"
                  />
                </div>
              </Permissions>
              <div>
                <div>Tags</div>
                <TagsFilter
                  title="Tags"
                  fetchActionTag={fetchTagsForFilter}
                  getTagCount={getTagsTotalCount}
                />
              </div>
              <Permissions action="users.list">
                <div>
                  <div>Reviewer</div>
                  <BackendResourceFilter
                    resource={resource}
                    fetchAction={fetchReviewers}
                    getResourceList={getUsersNoSuperAdminList}
                    getResourceCount={getUsersTotalCount}
                    getLoading={getUsersLoading}
                    filterKey="reviewed_by_user_uuids"
                    title="Reviewer"
                    nameKey="name"
                    dataTestid="reviewer-filter-button"
                    allowExclude
                  />
                </div>
              </Permissions>
              {furtherReviewTagGroup && furtherReviewTagGroup.tag_values.length > 0 && (
                <div>
                  <div>Workflow Stage</div>

                  <MultiSelectFilter
                    options={furtherReviewTagGroup.tag_values.reduce<Record<string, string>>(
                      (acc, tag) => {
                        acc[tag.uuid] = tag.value;
                        return acc;
                      },
                      {}
                    )}
                    filter="tags"
                    notFilter="not_tags"
                    allowMultipleValues
                  />
                </div>
              )}
              <div>
                <div>Comments</div>
                <MultiSelectFilter
                  options={{
                    true: 'Has Comments',
                    false: 'No Comments',
                  }}
                  filter="has_comments"
                />
              </div>
            </div>
          </EnvelopeListSidebarCollapsableSection>
          <EnvelopeListSidebarCollapsableSection
            headerTitle="Product"
            selectedFilters={
              treeParams.integration_types ||
              treeParams.key_actions ||
              treeParams.not_key_actions ||
              treeParams.key_actions_and ||
              treeParams.not_key_actions_and
            }
            isDefaultClosed
          >
            <div className="flex flex-col gap-y-3 mt-2">
              <Permissions action="communication_envelopes.list">
                <div>
                  <div>Type</div>
                  <MultiSelectFilter
                    options={{
                      api: 'Insight',
                      app: 'Prevent',
                    }}
                    filter="integration_types"
                    allowMultipleValues
                  />
                </div>
              </Permissions>
              <Permissions action="communication_envelopes.filter_actions">
                <div>
                  <div>End User Actions</div>
                  <ResourceFilter
                    getResourceList={getKeyActionsNames}
                    filterKey="key_actions"
                    title="Actions"
                    nameKey="name"
                  />
                </div>
              </Permissions>
            </div>
          </EnvelopeListSidebarCollapsableSection>
          <div className="flex w-full justify-center mt-8">
            <button
              type="button"
              onClick={handleOpenInfo}
              className="flex flex-row items-center gap-2 focus:outline-none"
            >
              {GRAY_INFO_ICON}{' '}
              <span className="text-litlingo-white underline">More information</span>
            </button>
          </div>
        </div>
      </div>

      <CreateReviewSetHowTo forceOpen={forceInfoModalOpen} />
      {isAdvancedSearchModalOpen && (
        <AdvancedSearchModal
          isModalOpen={isAdvancedSearchModalOpen}
          toggleModalOpen={toggleAdvancedSearchModalOpen}
        />
      )}
    </div>
  );
};

export default EventsFiltersSidebar;

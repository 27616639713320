import type { PayloadAction } from '@reduxjs/toolkit';
import { createReducer } from '@reduxjs/toolkit';
import {
  addModifiedItem,
  changeScope,
  ChangeScopePayload,
  cleanChangedAndNotSelectedNodes,
  cleanConfig,
  CreateNewGroupPayload,
  discardChanges,
  linkParentWithChild,
  LinkParentWithChildPayload,
  MatchBodyOnlyPayload,
  MoveItemPayload,
  selectCustomerConfig,
  SelectItemPayload,
  setCompareMode,
  setMatchBodyOnly,
  updateModeOfSpeech,
  UpdateModeOfSpeechPayload,
  UpdateModifierPayload,
  updateNegated,
  UpdateNegatedPayload,
} from 'actions/config';
import type {
  DeleteRelationshipFromConfigPayload,
  PatchRuleConfigWithRelationshipPayload,
} from 'actions/relationship';
import type { UpdateConfigGroupsPayload } from 'actions/rule';
import {
  fetchRelationshipsCompareSuccess,
  fetchRuleCompareConfigSuccess,
  fetchRuleCompareDiffSuccess,
} from 'actions/ruleGroup';
import type {
  AnnotatorRelationship,
  Modifiers,
  MRuleConfigNode,
  MRuleRevision,
  NormalizedResource,
  RuleChange,
  UUID,
} from 'types';
import { v4 as uuidv4 } from 'uuid';
import {
  cleanChangedNodes,
  cleanLastChangedNode,
  createNewGroup,
  createRuleConfig,
  deleteRelationshipFromConfig,
  fetchRuleConfigSuccess,
  moveItem,
  patchRuleConfigWithRelationship,
  patchSingleRuleConfig,
  removeItem,
  saveRuleConfigSuccess,
  selectItem,
  updateConfigGroups,
  updateModifier,
} from '../actions';

export type ConfigState = {
  lastChangedNode: UUID | null;
  selectedNode: number | null;
  modified: UUID[];
  added: UUID[];
  originalItems: NormalizedResource<MRuleConfigNode>;
  items: NormalizedResource<MRuleConfigNode>;
  selectedCustomer: string | null;

  compareRule: MRuleRevision | null;
  compareItems: NormalizedResource<MRuleConfigNode>;
  compareRelationships: NormalizedResource<AnnotatorRelationship>;
  rulesDiff: RuleChange[];
  compareMode: boolean;
};

export type ConfigReducer<P = void> = (
  state: ConfigState,
  payload: PayloadAction<P>
) => ConfigState;

export type PayloadPatchSingle = {
  id: UUID;
};

export const defaultState: ConfigState = {
  added: [],
  modified: [],
  lastChangedNode: null,
  selectedNode: null,
  originalItems: {},
  items: {},
  selectedCustomer: null,
  compareItems: {},
  compareRule: null,
  compareRelationships: {},
  rulesDiff: [],
  compareMode: false,
};

const handleRemoveItem: ConfigReducer<UUID> = (state, { payload }) => {
  const id = payload;
  const parentId = state.items[id].parent;
  // if you delete the root node
  if (parentId == null) {
    return defaultState;
  }

  const parent = state.items[parentId];
  if (!('groups' in parent)) return state;

  const items = { ...state.items };
  delete items[id];
  const index = parent.groups.indexOf(id);
  return {
    ...state,
    items: {
      ...items,
      [parentId]: {
        ...parent,
        groups: [...parent.groups.slice(0, index), ...parent.groups.slice(index + 1)],
      },
    },
    modified: [...state.modified.filter((i) => i !== id), id],
  };
};

// when a relationship is created - we need to reference that the relation ship is of this config
const handlePatchRuleConfigWithRelationship: ConfigReducer<
  PatchRuleConfigWithRelationshipPayload
> = (state, { payload }) => {
  const { configId, id } = payload;
  const item = state.items[configId];
  const relationship = 'relationship' in item ? item.relationship : [];
  if (relationship.length && relationship.indexOf(id) > -1) {
    return {
      ...state,
      modified: [...state.modified.filter((curId) => curId !== id), id],
    };
  }
  return {
    ...state,
    items: {
      ...state.items,
      [configId]: {
        ...item,
        relationship: [...relationship, id],
      },
    },
  };
};

const handleDeleteRelationshipFromConfig: ConfigReducer<DeleteRelationshipFromConfigPayload> = (
  state,
  { payload }
) => {
  const { configId, relationshipId } = payload;
  const item = state.items[configId];
  const relationship = 'relationship' in item ? item.relationship : [];
  const index = relationship.indexOf(relationshipId);
  if (!relationship.length && index === -1) return state;

  return {
    ...state,
    items: {
      ...state.items,
      [configId]: {
        ...item,
        relationship: [...relationship.slice(0, index), ...relationship.slice(index + 1)],
      },
    },
  };
};

const handleFetchRuleConfigSuccess: ConfigReducer<NormalizedResource<MRuleConfigNode>> = (
  state,
  { payload }
) => ({
  ...state,
  originalItems: {
    ...payload,
  },
  items: {
    ...payload,
  },
});

const handlePatchSingleRuleConfig: ConfigReducer<MRuleConfigNode> = (state, { payload }) => {
  const { id } = payload;
  const item = state.items[id] ? { ...state.items[id] } : {};
  return {
    ...state,
    items: {
      ...state.items,
      [id]: {
        ...item,
        ...payload,
      },
    },
    modified: [...state.modified.filter((index) => index !== id), id],
  };
};

const handleCreateNewGroup: ConfigReducer<CreateNewGroupPayload> = (state, { payload }) => {
  const id = uuidv4();
  const { parentId, futureNodeIndex } = payload;

  const parentItem = state.items[parentId];

  if (!('groups' in parentItem)) {
    return state;
  }

  const modifiers: Modifiers = {};

  return {
    ...state,
    items: {
      ...state.items,
      [id]: {
        id,
        name: 'RELATIONSHIP_MATCH',
        parent: parentId,
        groups: [],
        typeOfConfig: 'RELATIONSHIP_MATCH',
        modifiers,
        relationship: [],
      },
      [parentId]: {
        ...parentItem,
        ...{ groups: [...parentItem.groups, id] },
      },
    },
    added: [...state.added, id],
    lastChangedNode: id,
    selectedNode: futureNodeIndex || state.selectedNode,
  };
};

const handleMoveItem: ConfigReducer<MoveItemPayload> = (state, { payload }) => {
  const { newParentId, idToMove } = payload;
  const itemToMove = state.items[idToMove];

  if (itemToMove.parent == null) {
    return state;
  }

  const oldParent = state.items[itemToMove.parent];
  const newParent = state.items[newParentId];

  if (!('groups' in oldParent) || !('groups' in newParent)) {
    return state;
  }

  const index = oldParent.groups.indexOf(idToMove);
  const newParentGroups = newParent.groups || [];

  return {
    ...state,
    items: {
      ...state.items,
      [itemToMove.parent]: {
        ...oldParent,
        ...{
          groups: [...oldParent.groups.slice(0, index), ...oldParent.groups.slice(index + 1)],
        },
      },
      [newParentId]: {
        ...newParent,
        ...{ groups: [...newParentGroups, idToMove] },
      },
      [idToMove]: {
        ...itemToMove,
        ...{ parent: newParentId },
      },
    },
    modified: [...state.modified.filter((id) => id !== idToMove), idToMove],
    lastChangedNode: idToMove,
  };
};

const handleUpdateConfigGroups: ConfigReducer<UpdateConfigGroupsPayload> = (state, { payload }) => {
  const { parentId, newConfigId } = payload;
  const parentItem = state.items[parentId];
  if (!('groups' in parentItem)) {
    return state;
  }
  return {
    ...state,
    items: {
      ...state.items,
      [parentId]: {
        ...parentItem,
        ...{ groups: [...(parentItem.groups ?? []), newConfigId] },
      },
    },
    added: [...state.added, newConfigId],
    lastChangedNode: newConfigId,
  };
};

const handleUpdateModifier: ConfigReducer<UpdateModifierPayload> = (state, { payload }) => {
  const { id, modifiers } = payload;
  return {
    ...state,
    items: {
      ...state.items,
      [id]: {
        ...state.items[id],
        modifiers,
      },
    },
    modified: [...state.modified.filter((i) => i !== id), id],
  };
};

const handleSetMatchOnlyBody: ConfigReducer<MatchBodyOnlyPayload> = (state, { payload }) => {
  const { id, matchOnlyBody } = payload;
  return {
    ...state,
    items: {
      ...state.items,
      [id]: {
        ...state.items[id],
        body_only: matchOnlyBody,
      },
    },
    modified: [...state.modified.filter((i) => i !== id), id],
  };
};

const handleUpdateScoping: ConfigReducer<ChangeScopePayload> = (state, { payload }) => {
  const { act, customerId, nodeId } = payload;

  let modifiedItems = { ...state.items };
  const modified: string[] = [];

  const modify = (id: string): void => {
    const node = modifiedItems[id];
    modified.push(id);
    if (node) {
      let { modifiers } = node;

      if (act === 'add') {
        modifiers = {
          ...modifiers,
          DISALLOW_CUSTOMER_UUIDS: [...(modifiers?.DISALLOW_CUSTOMER_UUIDS || []), customerId],
        };
      } else {
        modifiers = {
          ...modifiers,
          DISALLOW_CUSTOMER_UUIDS: [
            ...(modifiers?.DISALLOW_CUSTOMER_UUIDS || []).filter((c) => c !== customerId),
          ],
        };
      }

      modifiedItems = {
        ...modifiedItems,
        [id]: {
          ...modifiedItems[id],
          modifiers,
        },
      };
    }
    if ('groups' in node) {
      for (let i = 0; i < node.groups.length; i += 1) {
        modify(node.groups[i]);
      }
    }
  };

  modify(nodeId);

  return {
    ...state,
    items: {
      ...modifiedItems,
    },
    modified: [...new Set([...state.modified, ...modified])],
  };
};

const handleUpdateNegated: ConfigReducer<UpdateNegatedPayload> = (state, action) => {
  const { id, negated } = action.payload;

  return {
    ...state,
    items: {
      ...state.items,
      [id]: {
        ...state.items[id],
        negated,
      },
    },
  };
};

const handleUpdateModeOfSpeech: ConfigReducer<UpdateModeOfSpeechPayload> = (state, action) => {
  const { id, mode } = action.payload;

  return {
    ...state,
    items: {
      ...state.items,
      [id]: {
        ...state.items[id],
        mode_of_speech: mode,
      },
    },
  };
};

const handleCleanChanges: ConfigReducer = (state) => ({
  ...state,
  added: [],
  modified: [],
  lastChangedNode: null,
});

const handleCleanChangesAndNotSelected: ConfigReducer = (state) => ({
  ...state,
  added: [],
  modified: [],
  lastChangedNode: null,
});

const handleSelectItem: ConfigReducer<SelectItemPayload> = (state, { payload }) => {
  const { index } = payload;
  return {
    ...state,
    selectedNode: index,
  };
};

const handleSelectCustomer: ConfigReducer<string | null> = (state, { payload }) => ({
  ...state,
  selectedCustomer: payload,
});

const handleCleanLastChangedNode: ConfigReducer = (state) => ({
  ...state,
  lastChangedNode: null,
});

const handleSaveRuleConfigSuccess: ConfigReducer<NormalizedResource<MRuleConfigNode>> = (
  state,
  { payload }
) => ({
  ...state,
  originalItems: {
    ...state.items,
    ...payload,
  },
  items: {
    ...state.items,
    ...payload,
  },
});

const handleCreateRuleConfig: ConfigReducer<NormalizedResource<MRuleConfigNode>> = (
  state,
  { payload }
) => ({
  ...state,
  items: {
    ...state.items,
    ...payload,
  },
});

const handleLinkParentWithChild: ConfigReducer<LinkParentWithChildPayload> = (state, action) => {
  const { parentId, childId } = action.payload;

  const parentNode = state.items[parentId];

  if (!('groups' in parentNode)) return state;

  return {
    ...state,
    items: {
      ...state.items,
      [parentId]: {
        ...state.items[parentId],
        groups: [...parentNode.groups, childId],
      },
      [childId]: {
        ...state.items[childId],
        parent: parentId,
      },
    },
  };
};

const handleDiscardChanges: ConfigReducer = (state) => ({
  ...state,
  items: state.originalItems,
  added: [],
  modified: [],
  lastChangedNode: null,
  selectedNode: null,
});

const handleAddModifiedItem: ConfigReducer<NormalizedResource<MRuleConfigNode>> = (
  state,
  { payload }
) => {
  const parentId = Object.values(payload).find((item) => item.parent == null)?.id;
  if (parentId == null) return state;
  return {
    ...state,
    items: {
      ...state.items,
      ...Object.values(payload).reduce((acc, cur) => ({ ...acc, [cur.id]: cur }), {}),
    },
    modified: [...state.modified.filter((id) => id !== parentId), parentId],
  };
};

const handleFetchRuleCompareConfigSuccess: ConfigReducer<
  ReturnType<typeof fetchRuleCompareConfigSuccess>['payload']
> = (state, { payload }) => ({
  ...state,
  compareItems: {
    ...payload.items,
  },
  compareRule: payload.rule,
});

const handleFetchRuleCompareDiffSuccess: ConfigReducer<
  ReturnType<typeof fetchRuleCompareDiffSuccess>['payload']
> = (state, { payload }) => ({
  ...state,
  rulesDiff: payload.rulesDiff,
});

const handleSaveRelationshipSuccess: ConfigReducer<NormalizedResource<AnnotatorRelationship>> = (
  state,
  { payload }
) => ({
  ...state,
  compareRelationships: payload,
});

const handleSetCompareMode: ConfigReducer<boolean> = (state, { payload }) => ({
  ...state,
  compareMode: payload,
});

const handleCleanConfig: ConfigReducer<boolean> = (state) => ({
  ...state,
  added: [],
  modified: [],
  lastChangedNode: null,
  selectedNode: null,
  originalItems: {},
  items: {},
  selectedCustomer: null,
  compareItems: {},
  compareRule: null,
  compareRelationships: {},
  rulesDiff: [],
  compareMode: false,
});

const handlers = {
  [removeItem.toString()]: handleRemoveItem,
  [patchRuleConfigWithRelationship.toString()]: handlePatchRuleConfigWithRelationship,
  [deleteRelationshipFromConfig.toString()]: handleDeleteRelationshipFromConfig,
  [fetchRuleConfigSuccess.toString()]: handleFetchRuleConfigSuccess,
  [patchSingleRuleConfig.toString()]: handlePatchSingleRuleConfig,
  [createNewGroup.toString()]: handleCreateNewGroup,
  [moveItem.toString()]: handleMoveItem,
  [updateConfigGroups.toString()]: handleUpdateConfigGroups,
  [createRuleConfig.toString()]: handleCreateRuleConfig,
  [cleanChangedNodes.toString()]: handleCleanChanges,
  [cleanChangedAndNotSelectedNodes.toString()]: handleCleanChangesAndNotSelected,
  [selectItem.toString()]: handleSelectItem,
  [selectCustomerConfig.toString()]: handleSelectCustomer,
  [cleanLastChangedNode.toString()]: handleCleanLastChangedNode,
  [updateModifier.toString()]: handleUpdateModifier,
  [changeScope.toString()]: handleUpdateScoping,
  [updateNegated.toString()]: handleUpdateNegated,
  [updateModeOfSpeech.toString()]: handleUpdateModeOfSpeech,
  [saveRuleConfigSuccess.toString()]: handleSaveRuleConfigSuccess,
  [linkParentWithChild.toString()]: handleLinkParentWithChild,
  [discardChanges.toString()]: handleDiscardChanges,
  [addModifiedItem.toString()]: handleAddModifiedItem,
  [setMatchBodyOnly.toString()]: handleSetMatchOnlyBody,

  [fetchRuleCompareConfigSuccess.toString()]: handleFetchRuleCompareConfigSuccess,
  [fetchRuleCompareDiffSuccess.toString()]: handleFetchRuleCompareDiffSuccess,
  [fetchRelationshipsCompareSuccess.toString()]: handleSaveRelationshipSuccess,

  [setCompareMode.toString()]: handleSetCompareMode,
  [cleanConfig.toString()]: handleCleanConfig,
};

const configReducer = createReducer(defaultState, handlers);

export default configReducer;

/* eslint-disable max-lines */
/* eslint-disable camelcase */

import { Tag, UUID } from '@litlingo/client';
import { bulkReviewEnvelope, fetchTagGroups } from 'actions';
import {
  addTagToReview,
  changeBulkReviewStatus,
  changeDeclaredStatus,
  changeTags,
  removeTagFromReview,
  setCommentError,
  setSelectedThread,
} from 'actions/envelopeReview';
import Modal from 'components/Modal';
import ModalAddTestSuitFromEvent from 'components/ModalAddTestSuiteFromEvent';
import Permissions from 'components/Permissions';
import keyMap from 'constants/configHotKeys';
import { INFO_ICON } from 'constants/dashboardIcons';
import { resourceQueryParamName } from 'constants/resourceQueryNames';
import { defaultReviewer } from 'constants/reviewSets';
import React, { useEffect, useRef, useState } from 'react';
import { GlobalHotKeys } from 'react-hotkeys';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  getContinueAssignmentLoading,
  getReviewAndContinueAssignmentLoading,
  getUsersSingleAssigment,
} from 'selectors/assignments';
import { getCustomerFurtherReviewTagGroup, getCustomerTagGroupsIds, getUser } from 'selectors/auth';
import getSelectedEnvelope, {
  getBulkReviewHasChanges,
  getBulkReviewStatus,
  getDeclaredStatus,
  getSelectedReviewer,
  getSelectedThreads,
  getTags,
} from 'selectors/envelopeReview';
import {
  getFetchSingleEnvelopeLoading,
  getLastReview,
  getReviewAndContinueLoading,
  getReviewEnvelopeLoading,
} from 'selectors/envelopes';
import { getNavParamsByResource } from 'selectors/nav';
import getEnvelopeReviewLabelOptions, { getHasOneReviewOption } from 'selectors/reviewStatus';
import {
  getFurtherReviewTagGroup,
  getTagGroupsForAssignment,
  getTagGroupsForCustomer,
} from 'selectors/tagGroup';
import { useSelector } from 'store';
import logEvent from 'utils/analytics';
import EnvelopeTags from '../../EnvelopeTags';
import GroupTags from './EnvelopeGroupTags';
import EnvelopeRadioGroupTags from './EnvelopeRadioGroupTags';
import StatusOptionsSection from './StatusOptionsSection';

type ComponentProps = {
  eventId: UUID;
  needFurtherReview: string;
  setNeedFurtherReview: React.Dispatch<React.SetStateAction<string>>;
};

const EnvelopeSidebarActions: React.FC<ComponentProps> = ({
  eventId,
  needFurtherReview,
  setNeedFurtherReview,
}) => {
  const dispatch = useDispatch();

  const { envelopeId } = useParams<{ envelopeId: string }>();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [infoModal, setInfoModal] = useState(false);

  const [isConfirmed, setIsConfirmed] = useState(false);

  const envelope = useSelector(getSelectedEnvelope);
  const selectedTags = useSelector(getTags);
  const hasChanges = useSelector(getBulkReviewHasChanges);
  const selectedThreads = useSelector(getSelectedThreads);
  const declaredStatus = useSelector(getDeclaredStatus);
  const { currentReview } = useSelector(getNavParamsByResource(resourceQueryParamName.envelopes));
  const lastReview = useSelector((state) => getLastReview(state, envelopeId));

  const reviewLoading = useSelector(getReviewEnvelopeLoading);

  const singleAssigment = useSelector(getUsersSingleAssigment);
  const tagGroups = useSelector((state) =>
    getTagGroupsForAssignment(state, singleAssigment?.uuid || '')
  );
  const customerTagGroupsIds = useSelector(getCustomerTagGroupsIds);
  const customerTagGroups = useSelector(getTagGroupsForCustomer);

  const furtherReviewTagGroupId = useSelector(getCustomerFurtherReviewTagGroup);
  const furtherReviewTagGroup = useSelector(getFurtherReviewTagGroup);

  const hasOneReviewOption = useSelector(getHasOneReviewOption);
  const envelopeDeclaredStatus = useSelector(getDeclaredStatus);

  const continueAssignmentLoading = useSelector(getContinueAssignmentLoading);
  const envelopeLoading = useSelector(getFetchSingleEnvelopeLoading);
  const reviewAndContinueLoading = useSelector(getReviewAndContinueLoading);
  const reviewAndContinueAssignmentLoading = useSelector(getReviewAndContinueAssignmentLoading);
  const isBulkReview = useSelector(getBulkReviewStatus);
  const reviewLabelOptions = useSelector(getEnvelopeReviewLabelOptions);
  const selectedReviewer = useSelector(getSelectedReviewer);

  const assignment = useSelector(getUsersSingleAssigment);
  const user = useSelector(getUser);

  const { current: customerTagGroupsIdsRef } = useRef(customerTagGroupsIds);

  const [startTime, setStartTime] = useState(0);

  const isAdmin = user.roles.includes('super-admin');

  useEffect(() => {
    setStartTime(performance.now());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [envelopeLoading, continueAssignmentLoading]);

  useEffect(() => {
    const tagGroupsIds = [
      ...(singleAssigment?.saved_search?.config?.tag_values_groups_uuids ?? []),
      ...(customerTagGroupsIdsRef ?? []),
      ...(furtherReviewTagGroupId ? [furtherReviewTagGroupId] : []),
    ];

    if (tagGroupsIds.length > 0) {
      dispatch(
        fetchTagGroups({
          uuids: tagGroupsIds,
        })
      );
    }
  }, [dispatch, singleAssigment, currentReview, customerTagGroupsIdsRef, furtherReviewTagGroupId]);

  useEffect(() => {
    if (envelope && envelope.review_value) {
      if (
        lastReview &&
        lastReview.review_value === envelope.review_value &&
        lastReview.review_value === declaredStatus
      ) {
        setIsConfirmed(lastReview.meta_data.is_confirmed || false);
      } else {
        setIsConfirmed(false);
      }
    }
  }, [envelope, lastReview, declaredStatus, dispatch]);

  const toggleModal = (): void => {
    setIsModalOpen(!isModalOpen);
  };

  const handleAddTag = (tag: Tag): void => {
    logEvent('envelope-add-tag-from-group');

    dispatch(
      addTagToReview({
        tag,
      })
    );
  };

  const handleRemoveTag = (tag: Tag): void => {
    logEvent('envelope-remove-tag-from-group');

    dispatch(
      removeTagFromReview({
        tag,
      })
    );
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    tag: Tag,
    unselectedTag?: Tag
  ): void => {
    const { checked } = e.currentTarget;
    if (checked) {
      handleAddTag(tag);
    } else {
      handleRemoveTag(tag);
    }

    if (unselectedTag) {
      handleRemoveTag(unselectedTag);
    }
  };

  const handleRemoveFurtherReviewTag = (): void => {
    const furtherReviewTag = selectedTags?.filter((t) =>
      furtherReviewTagGroup?.tag_values.some((tv) => tv.uuid === t.tag_value_uuid)
    );

    if (furtherReviewTag && furtherReviewTag[0]?.tag_value) {
      handleRemoveTag(furtherReviewTag[0].tag_value);
    }
  };

  const handleChangeDeclaredStatus = (value: string): void => {
    if (isAdmin) return;

    if (value !== 'escalated') {
      dispatch(setCommentError(false));
    }
    if (value === declaredStatus && hasOneReviewOption) {
      dispatch(changeDeclaredStatus({ status: 'pending' }));
    } else {
      dispatch(changeDeclaredStatus({ status: value }));
    }

    logEvent('envelope-status-change');

    if (value === 'escalated' || value === 'out_of_policy') {
      setNeedFurtherReview('true');
    } else {
      handleRemoveFurtherReviewTag();
      setNeedFurtherReview('false');
    }
  };

  const handleChangeFurtherReview = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = e.target;
    setNeedFurtherReview(value);

    if (value !== 'true') {
      handleRemoveFurtherReviewTag();
    }
  };

  const handleBulkReview = (): void => {
    const selectedEnvelopes = selectedThreads;
    const navigate = 'next';

    const meta_data =
      selectedReviewer && selectedReviewer !== defaultReviewer.value
        ? { meta_data: { reviewed_by: selectedReviewer } }
        : {};
    if (selectedEnvelopes.length && envelopeDeclaredStatus) {
      if (currentReview === singleAssigment?.saved_search_uuid) {
        dispatch(
          bulkReviewEnvelope({
            skip_if_unreviewed: true,
            envelopeIds: selectedEnvelopes,
            value: envelopeDeclaredStatus,
            currentEnvelope: envelopeId,
            secondsSpent: Math.round((performance.now() - startTime) / 1000),
            navigate,
            ...(assignment ? { assignmentId: assignment.uuid } : {}),
            ...meta_data,
          })
        );
      } else {
        dispatch(
          bulkReviewEnvelope({
            envelopeIds: selectedEnvelopes,
            currentEnvelope: envelopeId,
            value: envelopeDeclaredStatus,
            secondsSpent: Math.round((performance.now() - startTime) / 1000),
            ...meta_data,
          })
        );
      }

      if (!reviewAndContinueLoading && !reviewAndContinueAssignmentLoading) {
        dispatch(changeBulkReviewStatus(false));
      }
    }
  };

  if (!envelope) return null;

  const renderReviewTagGroups = (): JSX.Element => {
    const allGroups = [...tagGroups, ...customerTagGroups].filter(
      (g, idx, array) => idx === array.findIndex((a) => a.uuid === g.uuid)
    );

    return (
      <Permissions action="communication_envelopes.add_tags">
        <GroupTags handleChange={handleChange} envelope={envelope} tagGroups={allGroups} />
      </Permissions>
    );
  };

  const reviewHandlers = {
    in_policy: {
      handler: {
        ACCEPT_ENVELOPE: (): void => {
          handleChangeDeclaredStatus('in_policy');
        },
      },
    },
    out_of_policy: {
      handler: {
        REJECT_ENVELOPE: (): void => {
          handleChangeDeclaredStatus('out_of_policy');
        },
      },
    },
    escalated: {
      handler: {
        ESCALATE_ENVELOPE: (): void => {
          handleChangeDeclaredStatus('escalated');
        },
      },
    },
    reviewed: {
      handler: {
        REVIEW_ENVELOPE: (): void => {
          handleChangeDeclaredStatus('reviewed');
        },
      },
    },
  };

  const handlers = {
    ...Object.entries(reviewHandlers).reduce((acc, [key, value]) => {
      if (reviewLabelOptions[key as keyof typeof reviewLabelOptions]) {
        return { ...acc, ...value.handler };
      }
      return acc;
    }, {}),

    SAVE_ENVELOPE: (): void => {
      const button = (document.querySelector('#review-next-envelope') ||
        document.querySelector('#next-envelope-button')) as HTMLButtonElement;

      if (button) button.click();
    },
    FOCUS_TAGS_INPUT: (): void => {
      const input = document.querySelector('.react-tags__search-input') as HTMLInputElement;
      if (input) input.focus();
    },
  };

  const reviewStatus = {
    'Acceptable:': 'Ctrl + Alt/Option + A',
    'Out of Policy:': 'Ctrl + Alt/Option + O',
    'Escalated:': 'Ctrl + Alt/Option + Q',
    'Reviewed:': 'Ctrl + Alt/Option + R',
  };

  const controls = {
    'Save/Next:': 'Ctrl + Alt/Option + S',
  };

  const renderShorcutInfo = (
    <>
      <div>
        <span className="mt-1">Shorcuts for the review page</span>
        <div className="flex ">
          <div className="flex-1 mt-6">
            <span className="font-bold leading-5">Review Status</span>
            <div className="flex justify-center mt-4">
              <div className="table-row-group">
                {Object.entries(reviewStatus).map(([key, value]) => (
                  <div key={key} className="table-row h-7">
                    <div className="table-cell text-right">{key}</div>
                    <div className="table-cell text-left pl-2">{value}</div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div
            className="border-r border-litlingo-gray-1 mt-4"
            style={{ height: `${42 * Object.keys(reviewStatus).length}px` }}
          />
          <div className="flex-1 ml-4 mt-6">
            <span className="font-bold leading-5">Controls</span>
            <div className="flex justify-center mt-2">
              <div className="table-row-group">
                {Object.entries(controls).map(([key, value]) => (
                  <div key={key} className="table-row h-7">
                    <div className="table-cell text-right">{key}</div>
                    <div className="table-cell text-left pl-2">{value}</div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );

  return (
    <div className="flex flex-col p-4 gap-4 bg-white border border-litlingo-gray-1">
      <GlobalHotKeys keyMap={keyMap} handlers={handlers} />
      <div className="flex justify-end -mt-2 -mb-4">
        <button
          type="button"
          className="focus:outline-none"
          onClick={(): void => setInfoModal((value) => !value)}
        >
          {INFO_ICON}
        </button>
      </div>

      {infoModal && (
        <Modal
          body={renderShorcutInfo}
          title="Keyboard Shorcuts"
          okButton
          okButtonText="Close"
          okButtonOnClick={(): void => setInfoModal(false)}
          toggleShowModal={(): void => setInfoModal(false)}
          cancelButton={false}
          style={{
            width: '570px',
            minHeight: '302px',
          }}
        />
      )}

      <StatusOptionsSection
        declaredStatus={declaredStatus || ''}
        handleChangeDeclaredStatus={handleChangeDeclaredStatus}
        name="declared-status"
        loading={reviewLoading}
        extraAnnotation={isConfirmed ? '(Confirmed)' : ''}
      />

      {furtherReviewTagGroup && (
        <div className="flex flex-col gap-2 text-body">
          <div className="font-bold pb-1 border-b border-litlingo-gray-1">
            Requires Further Review?
          </div>

          <div className="flex flex-col gap-3 pl-2 text-small">
            <label htmlFor="radio-no-further-review" className="flex flex-row gap-1">
              <input
                type="radio"
                id="radio-no-further-review"
                name="further-review"
                value="false"
                className="input-radio"
                onChange={handleChangeFurtherReview}
                checked={needFurtherReview === 'false'}
                disabled={isAdmin}
              />
              <div
                className={`flex flex-row gap-1 w-full ${
                  needFurtherReview === 'false' ? 'bg-litlingo-primary-60 bg-opacity-25' : ''
                }`}
              >
                No
              </div>
            </label>

            <div className="flex flex-col gap-2">
              <label htmlFor="radio-further-review" className="flex flex-row gap-1">
                <input
                  type="radio"
                  id="radio-further-review"
                  name="further-review"
                  value="true"
                  className="input-radio"
                  onChange={handleChangeFurtherReview}
                  checked={needFurtherReview === 'true'}
                  disabled={isAdmin}
                />
                <div
                  className={`flex flex-row gap-1 w-full ${
                    needFurtherReview === 'true' ? 'bg-litlingo-primary-60 bg-opacity-25' : ''
                  }`}
                >
                  Yes, review with
                </div>
              </label>
              <div className="pl-6">
                <Permissions action="communication_envelopes.add_tags">
                  <EnvelopeRadioGroupTags
                    handleChange={handleChange}
                    tags={selectedTags || []}
                    tagGroup={furtherReviewTagGroup}
                    renderGroupName={false}
                    name="further-review-tags"
                    disabled={needFurtherReview === 'false' || isAdmin}
                  />
                </Permissions>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="flex flex-col w-full gap-3">
        {/* Render all tag groups in review */}
        {currentReview === singleAssigment?.saved_search_uuid && renderReviewTagGroups()}
        {/* Render only customer tag groups outside review */}
        {currentReview !== singleAssigment?.saved_search_uuid && (
          <Permissions action="communication_envelopes.add_tags">
            <GroupTags
              handleChange={handleChange}
              envelope={envelope}
              tagGroups={customerTagGroups}
            />
          </Permissions>
        )}
        {currentReview !== singleAssigment?.saved_search_uuid && (
          <div className="flex w-full">
            <Permissions action="communication_envelopes.add_tags">
              <EnvelopeTags envelopeId={envelopeId} />
            </Permissions>
          </div>
        )}
      </div>

      {isBulkReview && (
        <div className="flex flex-row">
          <span className="flex rounded">
            <button
              type="button"
              data-testid="cancel-button"
              className={`button flex justify-center text-base leading-5 font-bold text-litlingo-gray-5 bg-white border-litlingo-gray-3 w-37 h-8 text-center `}
              onClick={(): void => {
                dispatch(changeDeclaredStatus({ status: envelope.review_value || 'pending' }));
                dispatch(changeTags(envelope.tags || []));
                dispatch(changeBulkReviewStatus(false));
                dispatch(setSelectedThread([]));
              }}
            >
              Cancel
            </button>
          </span>
          <span className="flex rounded ml-2.5">
            <button
              type="button"
              data-testid="ok-button"
              className={`button button--primary flex justify-center text-base leading-5 font-bold text-white w-37 h-8 `}
              onClick={handleBulkReview}
              disabled={!hasChanges}
            >
              Apply
            </button>
          </span>
        </div>
      )}

      {isModalOpen && <ModalAddTestSuitFromEvent eventId={eventId} toggleModal={toggleModal} />}
    </div>
  );
};

export default EnvelopeSidebarActions;
